<template>
  <div class="breadcrumbs" v-if="breadcrumbs">
    <template v-for="({ name, link }, index) in breadcrumbs">
      <span
        class="breadcrumbs__link"
        :key="index + 1"
        @click="$router.push(link)"
      >
        {{ name }}
      </span>
      <IconMix
        class="breadcrumbs__icon"
        icon="arrow-right"
        :key="index"
        v-if="index < breadcrumbs.length - 1"
      />
    </template>
  </div>
</template>

<script>
import { computed, getCurrentInstance } from 'vue';
import IconMix from '@core/src/components/common/icon/IconMix.vue';

export default {
  components: { IconMix },
  setup() {
    const { proxy } = getCurrentInstance();

    const breadcrumbs = computed(() => {
      return proxy.$route.meta.breadcrumbs;
    });

    return {
      breadcrumbs,
    };
  },
};
</script>

<style lang="scss">
.breadcrumbs {
  font-weight: 400;
  font-size: 17px;
  line-height: 24px;
  display: flex;
  align-items: center;

  &__link {
    cursor: pointer;
  }

  &__icon {
    margin: 0 11px;
  }
}
</style>
