<template>
  <div class="administration">
    <div class="administration__title">Administration</div>
    <div class="administration__menu">
      <ButtonCard
        v-for="(item, index) in menu"
        :key="index"
        :name="item.name"
        @click="$router.push(item.link)"
      />
    </div>
  </div>
</template>

<script>
import { ref } from 'vue';
import ButtonCard from '@/components/cards/ButtonCard.vue';

export default {
  components: {
    ButtonCard,
  },
  setup() {
    const menu = ref([
      { name: 'User', link: '/administration/user' },
      // { name: 'Settings', link: '' },
      // { name: 'Infrastructure', link: '' },
    ]);
    return { menu };
  },
};
</script>

<style lang="scss">
.administration {
  &__menu {
    max-width: 540px;
    display: flex;
    flex-wrap: wrap;
    column-gap: 20px;
    row-gap: 18px;
    margin-right: 68px;
  }

  &__title {
    font-weight: 900;
    font-size: 24px;
    line-height: 28px;
    margin-bottom: 16px;
  }
}
</style>
