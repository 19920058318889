import axios from 'axios';

export default {
  async GET_USER({ commit }, { email, id }) {
    const { data } = await axios.post('/api/admin/getUser', {
      email: email?.value,
      id,
    });
    commit('SET_USER', data);
    return data;
  },

  async GET_USER_SETTINGS({ commit }, user_id) {
    const { data } = await axios.get(`/api/usersetting/list/${user_id}`);
    commit('SET_USER_SETTINGS', data);
    return data;
  },

  async GET_USER_PROJECTS({ commit }, user_id) {
    const { data } = await axios.get(`/api/admin/listProjects/${user_id}`);
    commit('SET_USER_PROJECTS', data);
    return data;
  },

  async DELETE_PROJECT({ commit }, { user_id, project_id }) {
    // await axios.delete(`/api/project/${project_id}`);
    await axios.post(`/api/admin/pipelineDelete/${user_id}/${project_id}`);
    commit('DELETE_PROJECT', project_id);
    return {};
  },

  async DELETE_SETTINGS(
    { commit },
    { projectId: project_id, setting: key, user_id, index }
  ) {
    await axios.delete(
      `/api/usersetting/delete/${user_id}/${key}/${project_id || null}`
    );

    commit('DELETE_SETTINGS', { project_id: project_id, index });
    return {};
  },

  async START_PIPELINE({ commit }, { user_id, project_id }) {
    const { data } = await axios.post(
      `/api/admin/pipelineStart/${user_id}/${project_id}`
    );
    commit('START_PIPELINE', { project_id, data });
    return data;
  },

  async STOP_PIPELINE({ commit }, { user_id, project_id }) {
    const { data } = await axios.post(
      `/api/admin/pipelineStop/${user_id}/${project_id}`
    );
    commit('STOP_PIPELINE', project_id);
    return data;
  },

  CLEAR_DATA({ commit }) {
    commit('CLEAR_DATA');
  },
};
