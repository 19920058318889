var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"folders-modal"},[_c('div',{staticClass:"folders-modal__title"},[_vm._v("Project path")]),_c('div',{staticClass:"line"}),_c('div',{staticClass:"folders-modal__content"},[(_vm.currentDesc.length)?_c('div',{staticClass:"folders-modal__item folders-modal__back-folder",on:{"click":_vm.upTheFolder}},[_c('IconMix',{staticClass:"folders-modal__icon",attrs:{"icon":"back"}}),_c('span',{staticClass:"mts-p3-regular"},[_vm._v(_vm._s(_vm.backPath))])],1):_vm._e(),_vm._l((_vm.listNodes),function(item,index){return _c('div',{key:index,class:[
        'folders-modal__item',
        { 'folders-modal__item_active': item.isActive },
      ],on:{"click":function($event){return _vm.openFolder(item)}}},[_c('IconMix',{staticClass:"folders-modal__icon",attrs:{"icon":"folder"}}),(item?.isNewFolder)?_c('input',{directives:[{name:"model",rawName:"v-model",value:(item.name),expression:"item.name"}],ref:"inputs",refInFor:true,staticClass:"folders-modal__input",domProps:{"value":(item.name)},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.inputFocusOut(item, 'enter')},"focusout":function($event){return _vm.inputFocusOut(item)},"keypress":_vm.onlyText,"input":function($event){if($event.target.composing)return;_vm.$set(item, "name", $event.target.value)}}}):_c('span',{staticClass:"mts-p3-regular"},[_vm._v(_vm._s(item.name))])],1)}),(!_vm.listNodes.length && _vm.type === 'create')?_c('div',{staticClass:"folders-modal__item",on:{"click":_vm.addNewFolder}},[_vm._v(" add path ")]):_vm._e(),(_vm.listSession.length)?[_c('div',{staticClass:"line"}),_vm._l((_vm.listSession),function(item,index){return _c('div',{key:`${item.name}${index}`,class:[
          'folders-modal__item',
          'folders-modal__item-session',
          {
            'folders-modal__item_active':
              item?.name === _vm.selectedProject?.name,
          },
          {
            'folders-modal__item_disabled': _vm.type === 'create',
          },
        ],on:{"click":function($event){return _vm.selectProject(item)}}},[_c('div',[_c('IconMix',{staticClass:"folders-modal__icon",attrs:{"icon":"file"}}),_c('span',{staticClass:"mts-p3-regular"},[_vm._v(_vm._s(item.name))])],1),(_vm.type !== 'create')?_c('IconMix',{staticClass:"folders-modal__icon-folder-close",attrs:{"icon":"close-folder"},nativeOn:{"click":function($event){$event.stopPropagation();return _vm.deleteProject(item)}}}):_vm._e()],1)})]:_vm._e()],2),_c('div',{staticClass:"line"}),_c('div',{staticClass:"folders-modal__footer"},[(_vm.type === 'create')?_c('MixButton',{staticClass:"folders-modal__button_new",attrs:{"size":"medium","type":"secondary"},on:{"click":_vm.addNewFolder},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('IconMix',{attrs:{"viewBox":"0 0 10 10","width":"16","height":"16","icon":"button-plus"}})]},proxy:true}],null,false,1833827045)},[_c('span',[_vm._v("New folder")])]):_vm._e(),(_vm.type === 'create')?_c('MixButton',{staticClass:"folders-modal__button_save",attrs:{"size":"medium","disabled":_vm.currentPath.ppt_desc === ''},on:{"click":function($event){return _vm.$emit('saveFolder', _vm.currentPath)}}},[_c('span',[_vm._v("Save")])]):_vm._e(),(_vm.type === 'open')?_c('MixButton',{staticClass:"folders-modal__button_save",attrs:{"size":"medium","disabled":_vm.selectedProject === null},on:{"click":function($event){return _vm.$emit('selectProject', _vm.selectedProject)}}},[_c('span',[_vm._v("Open")])]):_vm._e()],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }