<template>
  <div :class="`button-card button-card_${type}`" @click="$emit('click', name)">
    <div class="button-card__icon" v-if="icon">
      <IconMix :icon="icon" />
      <div class="button-card__icon-label" v-if="iconLabel">
        {{ iconLabel }}
      </div>
    </div>
    <span>{{ name }}</span>
  </div>
</template>

<script>
import IconMix from '@core/src/components/common/icon/IconMix.vue';
// import { ref } from 'vue';
export default {
  name: 'ButtonCard',
  components: { IconMix },
  props: {
    name: String,
    icon: String,
    type: {
      default: 'primary',
      type: String,
    },
    iconLabel: {
      default: '',
      type: String,
    },
  },
  setup() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
.button-card {
  background-color: var(--color-background-modal);
  width: 250px;
  min-height: 104px;
  padding: 32px 30px 32px 30px;
  border-radius: 8px;
  font-weight: 400;
  font-size: 24px;
  display: flex;
  flex-direction: column;
  justify-content: center;

  &:hover {
    cursor: pointer;
    background-color: var(--color-constant-greyscale-600);
  }

  &:active {
    background-color: var(--color-text-secondary-link-hover);
  }

  &__icon {
    line-height: 0;
    margin-bottom: 13px;
    margin-left: 6px;
    display: flex;
    align-items: center;

    svg {
      min-width: 32px;
    }
  }

  &__icon-label {
    font-family: 'MTS Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    margin-left: 12px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &_secondary {
    background-color: var(--color-control-primary-active);

    &:hover {
      background-color: #f3101c;
    }
  }
}
</style>
