<template>
  <div class="mixer-page">
    <span class="mixer-page__title">Project Shutdown</span>
    <div>
      <RouterLink to="/">Main Page</RouterLink>
    </div>
    <iframe
      v-if="isRunMixer"
      class="iframe"
      ref="iFrame"
      allow="geolocation; microphone; camera; midi; encrypted-media; battery; usb; vibrate; fullscreen; autoplay;"
    ></iframe>
    <Preloader v-if="isLoading" />
    <InterviewModal />
  </div>
</template>

<script>
import { getCurrentInstance, onMounted, ref } from 'vue';
import Preloader from '@/components/common/Preloader.vue';
import axios from 'axios';
import InterviewModal from '@/components/modals/InterviewModal.vue';

export default {
  components: { Preloader, InterviewModal },
  setup() {
    const { proxy } = getCurrentInstance();
    const route = proxy.$route;
    const { id, type } = route.query;
    const iFrame = ref(null);
    const isLoading = ref(true);
    const store = proxy.$store;

    const authFrame = async (authKey, domain) => {
      await iFrame.value.contentWindow.postMessage(
        `auth:${authKey}`,
        `https://${domain}`
      );
    };

    const isRunMixer = ref(false);

    const getKey = async () => {
      try {
        const url =
          type === 'admin' && store.state.auth.user.admin
            ? '/api/admin/pipeline/auth-key'
            : '/api/project/auth-key';
        const { data } = await axios.post(url, {
          mixerId: id,
        });
        isRunMixer.value = true;
        return data;
      } catch ({ response: { status } }) {
        if (status === 404) isRunMixer.value = false;
        isLoading.value = false;
        throw new Error('Микшер не запущен');
      }
    };

    const onMessageClose = ({ data }) => {
      if (data === 'mixer-websocket-close') {
        isRunMixer.value = false;
      }
    };

    onMounted(async () => {
      try {
        const { token, domain, desc } = await getKey();
        iFrame.value.src = `//${domain}`;
        iFrame.value.onload = async () => {
          await authFrame(token, domain);
          setTimeout(async () => {
            isLoading.value = false;
          }, 1000);
        };
        window.addEventListener('message', onMessageClose);
        if (desc) {
          document.title = `CloudMix - ${desc.split('/').pop()}` || '';
        }
      } catch (error) {
        //eslint-disable-next-line
        console.log(error);
      }
    });
    return {
      iFrame,
      isLoading,
      isRunMixer,
    };
  },
};
</script>

<style lang="scss" scoped>
.mixer-page {
  &__title {
    font-family: 'MTS Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 28px;
  }
}
.iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  border: none;
}
</style>
