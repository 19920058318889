<template>
  <div class="app">
    <slot />
  </div>
</template>

<script>
export default {
  components: {},
  setup() {},
};
</script>

<style lang="scss" scoped>
.app {
  width: 100%;
  min-height: 100vh;
  padding: 32px 40px;
  display: flex;
  flex-direction: column;

  &__logo {
    margin-bottom: 36px;
  }

  &__breadcrumbs {
    margin-bottom: 40px;
  }
}
</style>
