<template>
  <div class="preloader">
    <IconMix icon="preloader" class="preloader__icon" />
  </div>
</template>

<script>
import IconMix from '@core/src/components/common/icon/IconMix.vue';
export default {
  setup() {
    return {};
  },
  components: { IconMix },
};
</script>

<style lang="scss" scoped>
.preloader {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--color-background-primary);
  z-index: 99;

  &__icon {
    animation: Preloader 2s linear infinite;
  }

  @keyframes Preloader {
    from {
      -webkit-transform: rotate(0deg);
    }
    to {
      -webkit-transform: rotate(360deg);
    }
  }
}
</style>
