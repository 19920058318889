<template>
  <mts-root theme="dark">
    <component :is="layout">
      <router-view />
    </component>
  </mts-root>
</template>

<script>
import { MtsRoot } from '@mts-ds/components-vue2';
import Vue, { computed, getCurrentInstance, onMounted } from 'vue';
import { detect } from 'detect-browser';

export default {
  components: {
    MtsRoot,
  },
  setup() {
    const { proxy } = getCurrentInstance();
    const store = proxy.$store;

    onMounted(() => {
      if (detect().name !== 'chrome' && window.innerWidth > 1440) {
        Vue.$toast(
          'Полноценная работа сервиса \nвозможна лишь в браузере google chrome \nили другом на движке chromium',
          {
            timeout: 5000,
            type: 'error',
          }
        );
      }
      if (layout.value !== 'error-layout') {
        store.dispatch('dictionary/GET_DICTIONARIES');
      }
    });

    const layout = computed(() => {
      return proxy.$route.meta.layout || 'default-layout';
    });

    return {
      layout,
    };
  },
};
</script>

<style lang="scss">
.app {
  width: 100%;
  min-height: 100vh;
}
</style>
