var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:[
    'select-container',
    `select-container_${_vm.size}`,
    {
      error: _vm.error !== '',
    },
  ]},[_c('div',{staticClass:"select-container__label"},[_vm._v(_vm._s(_vm.label))]),_c('Multiselect',_vm._b({staticClass:"select-container__select",attrs:{"options":_vm.options,"value":_vm.selectValue,"show-labels":false,"label":_vm.labelSelect},on:{"select":(value) => {
        _vm.$emit('select', value);
      }},model:{value:(_vm.selectValue),callback:function ($$v) {_vm.selectValue=$$v},expression:"selectValue"}},'Multiselect',_vm.$attrs,false))],1)
}
var staticRenderFns = []

export { render, staticRenderFns }