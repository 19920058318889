import axios from 'axios';

export default {
  AUTH_USER({ commit }, token) {
    commit('SET_AUTH_USER', token);
  },
  LOGOUT_USER({ commit }) {
    commit('LOGOUT_USER');
  },
  async GET_USER({ commit }) {
    const { data } = await axios.get('/api/whoami');
    commit('SET_USER', data);
  },
};
