import axios from 'axios';
import { getMapDesc } from '@/helpers/folder';

export default {
  async GET_FOLDERS({ commit }) {
    const { data } = await axios.get('/api/projects/list');

    const mapFolder = getMapDesc(data);
    commit('SET_FOLDER', mapFolder);
    commit('SET_ORIGIN_FOLDER', data);
  },

  ADD_FOLDER({ commit }, desc) {
    commit('ADD_FOLDER', desc);
  },

  ADD_SESSION({ commit }, session) {
    commit('ADD_SESSION', session);
  },

  CHANGE_DESC_FOLDER({ commit }, { desc, isDublicate }) {
    commit('SET_DESC_FOLDER', { desc, isDublicate });
  },

  CHANGE_ACTIVE_FOLDER({ commit }, desc) {
    commit('SET_ACTIVE_FOLDER', desc);
  },

  SELECT_ACTIVE_PROJECT({ commit }, project) {
    commit('SELECT_ACTIVE_PROJECT', project);
  },

  async DELETE_PROJECT({ commit }, project) {
    await axios.delete(`api/project/${project.ppt_id}`);
    commit('DELETE_PROJECT', project);
  },

  async STOP_PROJECT({ commit }, project) {
    await axios.post(`/api/stopProject/${project.ppt_id}`);
    commit('STOP_PROJECT', project);
  },
};
