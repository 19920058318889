<template>
  <div class="restream template-grid">
    <RestreamInputCard class="restream__card" />
    <RestreamOutputCard class="restream__card" />
  </div>
</template>

<script>
import RestreamInputCard from '@/components/cards/RestreamInputCard.vue';
import RestreamOutputCard from '@/components/cards/RestreamOutputCard.vue';
// import { getCurrentInstance, onMounted } from 'vue';

export default {
  name: 'RestreamPage',
  components: {
    RestreamInputCard,
    RestreamOutputCard,
  },
  setup() {
    return {};
  },
};
</script>

<style lang="scss">
.restream {
  &__card {
    grid-column: 1/5;

    @include between('middle', 'large') {
      grid-column: 1/7;
    }

    @include at('hd') {
      grid-column: 1/4;
    }
  }
}
</style>
