import Vue from 'vue';

export default {
  SET_USER(state, user) {
    state.user = user;
  },
  SET_USER_SETTINGS(state, settings) {
    Vue.set(state, 'userSettings', settings);
    // state.userSettings = settings;
  },
  SET_USER_PROJECTS(state, projects) {
    state.userProjects = projects;
  },
  DELETE_PROJECT(state, project_id) {
    const index = state.userProjects.findIndex(
      (item) => item.ppt_id === project_id
    );
    state.userProjects.splice(index, 1);
  },
  DELETE_SETTINGS(state, { index }) {
    // const index = state.userSettings.findIndex(
    //   (item) => item.ppt_id === project_id
    // );
    state.userSettings.splice(index, 1);
  },
  START_PIPELINE(state, { project_id, data }) {
    const index = state.userProjects.findIndex(
      (item) => item.ppt_id === project_id
    );
    Vue.set(state.userProjects[index], 'run_now', 1);
    Vue.set(state.userProjects[index].info, 'mixer_id', data[0].mixer_id);
    // state.userProjects.splice(index, 1);
  },
  STOP_PIPELINE(state, project_id) {
    const index = state.userProjects.findIndex(
      (item) => item.ppt_id === project_id
    );
    Vue.set(state.userProjects[index], 'run_now', 0);
    Vue.set(state.userProjects[index].info, 'node_name', '');
  },

  CLEAR_DATA(state) {
    state.userProjects = [];
    state.user = null;
    state.userSettings = [];
  },
};
