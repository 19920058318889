<template>
  <div class="new-mix-form">
    <div class="new-mix-form__field">
      <div class="new-mix-form__form-group">
        <MtsInput
          size="s"
          placeholder="Project Name"
          v-model="form.ppt_config.mixer.name"
          type="text"
          :valid="errorsList.name ? false : undefined"
          @mtsBlur="validateForm"
        />
        <div class="new-mix-form__input-label_error">
          <span v-if="errorsList.name">{{ errorsList.name.message }}</span>
        </div>
      </div>
    </div>
    <div class="new-mix-form__field">
      <div class="new-mix-form__form-group">
        <MtsDropdown
          v-model="form.ppt_config.mixer.version"
          size="s"
          placeholder="Сhannel"
          :valid="errorsList.version ? false : undefined"
          @mtsBlur="validateForm"
        >
          <template v-for="(channel, index) in channels">
            <MtsDropdownOption
              :key="index"
              :value="channel.value"
              v-if="channel.enable"
            >
              {{ channel.name }}
            </MtsDropdownOption>
          </template>
        </MtsDropdown>
        <div class="new-mix-form__input-label_error">
          <span v-if="errorsList.version">{{
            errorsList.version.message
          }}</span>
        </div>
      </div>
    </div>
    <div class="new-mix-form__form-group">
      <div class="new-mix-form__field new-mix-form__field_flex">
        <div
          :class="[
            'new-mix-form__folder mts-p2-regular',
            { 'new-mix-form__folder_active': form.ppt_desc !== '' },
            {
              'new-mix-form__folder_error': !!errorsList.version,
            },
          ]"
          @click="isOpenFolder = true"
        >
          {{ form.ppt_desc || 'Volume/folder' }}
        </div>
        <MixButton
          class="new-mix-form__button"
          type="icon"
          icon="button-plus"
          @click="isOpenFolder = true"
        />
      </div>
      <div class="new-mix-form__input-label_error">
        <span v-if="errorsList.ppt_desc">{{
          errorsList.ppt_desc.message
        }}</span>
      </div>
    </div>
    <div class="new-mix-form__form-group">
      <MtsCard class="new-mix-form__field new-mix-form__field_standarts">
        <MtsCardBody>
          <span class="mts-p1-regular new-mix-form__screen-label">
            Video standard
          </span>
          <div>
            <MtsRadioGroup
              @mtsChange="validateForm"
              class="new-mix-form__screens"
              v-model="form.ppt_config.mixer.format"
            >
              <div v-for="(group, index) in listScreen" :key="index">
                <template v-for="item in group">
                  <MtsFormGroup :key="item.value" v-if="item.enable">
                    <MtsLabel>{{ item.name }}</MtsLabel>
                    <MtsRadio size="s" :value="item.value"></MtsRadio>
                  </MtsFormGroup>
                </template>
              </div>
            </MtsRadioGroup>
          </div>
        </MtsCardBody>
      </MtsCard>
      <div class="new-mix-form__input-label_error">
        <span v-if="errorsList.format">{{ errorsList.format.message }}</span>
      </div>
    </div>
    <div class="new-mix-form__controls">
      <MixButton
        @click="saveSession"
        class="new-mix-form__button_save"
        type="secondary"
        :disabled="!!Object.keys(errorsList).length"
      >
        Save
      </MixButton>
      <MixButton
        v-if="form.run_now"
        @click="startRunProject"
        class="new-mix-form__button_start"
        :disabled="!!Object.keys(errorsList).length"
      >
        Start
      </MixButton>
      <MixButton
        v-else
        :disabled="!!Object.keys(errorsList).length"
        @click="startMixer"
        class="new-mix-form__button_start"
      >
        Start
      </MixButton>
    </div>
    <ModalMix :isOpen="isOpenFolder" @close="isOpenFolder = false">
      <FoldersModal @saveFolder="addFolderInInput" :descValue="form.ppt_desc" />
    </ModalMix>
    <Preloader v-if="isLoading" />
  </div>
</template>

<script>
import {
  MtsInput,
  MtsDropdown,
  MtsCard,
  MtsCardBody,
  MtsRadioGroup,
  MtsFormGroup,
  MtsRadio,
  MtsLabel,
  MtsDropdownOption,
} from '@mts-ds/components-vue2';
import MixButton from '@core/src/components/common/buttons/MixButton.vue';
import Vue, { computed, onMounted, ref, toRefs, watch } from 'vue';
import ModalMix from '@/components/modals/ModalMix.vue';
import FoldersModal from '@/components/modals/FoldersModal.vue';
import axios from 'axios';
import { getCurrentInstance } from 'vue';
import router from '@/router';
import Preloader from '@/components/common/Preloader.vue';
import Joi from 'joi';
import { useRoute } from 'vue-router/composables';
import { isEqual } from 'lodash';

export default {
  name: 'NewMixForm',
  components: {
    MtsInput,
    MtsDropdown,
    MtsDropdownOption,
    MixButton,
    MtsCard,
    MtsFormGroup,
    MtsCardBody,
    MtsRadioGroup,
    MtsRadio,
    MtsLabel,
    ModalMix,
    FoldersModal,
    Preloader,
  },
  props: {
    type: {
      default: 'new',
      type: String,
    },
  },
  setup(props) {
    const {
      proxy: { $store: store },
    } = getCurrentInstance();

    const route = useRoute();

    const listScreen = computed(() => {
      return store.state.dictionary.videoFormats.reduce((acc, current) => {
        const key = current.name.split('/')[0];
        if (current.enable) {
          (acc[key] = acc[key] || []).push({
            name: current.name,
            value: current.name,
            enable: current.enable,
          });
        }
        return acc;
      }, {});
    });

    const channels = computed(() => {
      return (
        store.state.dictionary.mixerFormats.map((item) => ({
          name: item.name,
          value: item.name,
          enable: item.enable,
        })) || []
      );
    });

    const form = ref({
      ppt_desc: '',
      ppt_config: {
        mixer: {
          name: '',
          version: '',
          format: '',
        },
        sources: [],
        destinations: [],
      },
    });

    const schema = computed(() => {
      return Joi.object().keys({
        ppt_desc: Joi.string().required(),
        ppt_config: Joi.object().keys({
          mixer: Joi.object().keys({
            name: Joi.string().required(),
            version: Joi.string().required(),
            format: Joi.string().required(),
          }),
        }),
      });
    });

    const errorsList = ref([]);

    const validateForm = () => {
      if (!isFirstSend.value) return;
      const options = {
        messages: {
          'string.empty': 'required fields to be filled',
        },
        abortEarly: false,
        stripUnknown: true,
      };
      const { error: { details = [] } = {} } = schema.value.validate(
        form.value,
        options
      );

      errorsList.value = details.reduce((acc, current) => {
        acc[current.context.key] = {
          message: current.message,
          type: current.type,
        };
        return acc;
      }, {});
    };

    const isOpenFolder = ref(false);

    const addFolderInInput = (item) => {
      form.value.ppt_desc = item.ppt_desc;
      isOpenFolder.value = false;
      validateForm();
    };

    const activeProject = ref(null);

    const { type } = toRefs(props);

    watch(type, () => {
      if (type.value === 'New') updateForm();
    });

    const saveSession = async () => {
      isFirstSend.value = true;
      validateForm();
      if (Object.keys(errorsList.value).length > 0) return;
      try {
        const { data } = await axios.post(
          `/api/project/${
            activeProject.value?.ppt_id ? activeProject.value?.ppt_id : 'new'
          }`,
          {
            ...form.value,
            ppt_desc: `${form.value.ppt_desc}/${form.value.ppt_config.mixer.name}`,
          }
        );

        Vue.$toast('Сессия успешно сохранена', {
          timeout: 5000,
          type: 'succes',
        });

        if (!activeProject.value?.ppt_id) {
          store.dispatch('folder/ADD_SESSION', {
            ...data,
            ppt_desc: `${form.value.ppt_desc}/${form.value.ppt_config.mixer.name}`,
            ...form.value,
          });
        }

        activeProject.value = data;
      } catch (error) {
        if (error.response.data.code === '23505') {
          Vue.$toast.clear();
          Vue.$toast('Dublicate name project', {
            timeout: 5000,
            type: 'error',
          });
        }
        throw error;
      }
    };

    const updateForm = () => {
      form.value = {
        ppt_desc: '',
        ppt_config: {
          mixer: {
            name: '',
            version: '',
            format: '',
          },
          sources: [],
          destinations: [],
        },
      };
    };
    const activeProjectStore = computed(() => {
      return store.state.folder.activeProject;
    });

    const cashForm = ref(null);

    onMounted(async () => {
      if (!activeProjectStore.value && route.query.id) {
        const { data } = await axios.get(`api/project/${route.query.id}`);
        await store.dispatch('folder/SELECT_ACTIVE_PROJECT', {
          ...data,
          ppt_id: route.query.id,
        });
      }

      if (props.type === 'New') {
        updateForm();
      } else {
        activeProject.value = activeProjectStore.value;
        form.value = {
          ...form.value,
          ...activeProjectStore.value,
        };
        cashForm.value = JSON.parse(JSON.stringify(form.value));
      }
    });

    const isLoading = ref(false);
    const isFirstSend = ref(false);

    const startRunProject = async () => {
      isFirstSend.value = true;
      validateForm();
      if (Object.keys(errorsList.value).length > 0) return;
      try {
        const {
          data: { module_id },
        } = await axios.get(`/api/projectInfo/${form.value.ppt_id}`);

        router.push({
          name: 'MixerPage',
          query: { id: module_id },
        });
      } catch (error) {
        //eslint-disable-next-line
        console.log(error);
      }
    };

    const startMixer = async () => {
      validateForm();
      isFirstSend.value = true;
      if (Object.keys(errorsList.value).length > 0) return;
      isLoading.value = true;

      try {
        if (!isEqual(form.value, cashForm.value)) {
          await saveSession();
        }
        const { data } = await axios.post(
          `/api/startProject/${activeProject.value.ppt_id}`
        );

        const currentMixer = data.find((item) => item.type === 'mixer');

        if (currentMixer) {
          router.push({
            name: 'MixerPage',
            query: { id: currentMixer.id },
          });
          isLoading.value = false;
        } else {
          Vue.$toast('микшер отсутствует в проекте', {
            timeout: 5000,
            type: 'error',
          });
        }
      } catch (error) {
        //eslint-disable-next-line
        console.log(error);
        isLoading.value = false;
      }
    };

    const stopMixer = async () => {
      await axios.post(`/api/stopProject/${activeProject.value.ppt_id}`);
    };

    return {
      listScreen,
      form,
      isOpenFolder,
      addFolderInInput,
      saveSession,
      channels,
      startMixer,
      activeProject,
      stopMixer,
      isLoading,
      startRunProject,
      activeProjectStore,
      errorsList,
      validateForm,
      isFirstSend,
      cashForm,
    };
  },
};
</script>

<style lang="scss" scoped>
.new-mix-form {
  width: 435px;

  &__input-label_error {
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: var(--color-text-negative);
    height: 12px;
  }

  &__form-group {
    margin-bottom: 10px;
  }

  &__field {
    // margin-bottom: 20px;

    &_flex {
      display: flex;
    }

    &_standarts {
      background-color: var(--color-background-primary-elevated);
      border-radius: 6px;
    }
  }

  &__button {
    width: 90px;
  }

  &__screens {
    display: flex;
    justify-content: space-between;
  }

  &__folder {
    width: 100%;
    margin-right: 7px;
    background-color: var(--color-background-primary-elevated);
    border-radius: 6px;
    padding: 6px 12px;
    color: var(--color-text-secondary);

    &_active {
      color: var(--color-text-primary);
    }

    &_error {
      box-sizing: border-box;
      box-shadow: inset 0px 0px 0 2px var(--color-accent-negative);
    }
  }

  &__screen-label {
    color: var(--color-text-primary);
    margin-bottom: 20px;
    display: inline-block;
  }

  &__controls {
    display: flex;
    justify-content: flex-end;
  }

  &__button {
    &_save {
      margin-right: 14px;
      width: 146px;
    }
    &_start {
      width: 146px;
    }
  }
}
</style>
