<template>
  <div class="user-card">
    <div class="user-card__header">{{ userInfo.user_name }}</div>
    <div class="user-card__content">
      <div class="user-card__item">{{ userInfo.user_id }}</div>
      <div class="user-card__item">{{ userInfo.user_email }}</div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    userInfo: {
      type: Object,
      default: () => ({}),
    },
  },
  setup() {
    return {};
  },
};
</script>

<style lang="scss">
.user-card {
  border-radius: 6px;
  overflow: hidden;

  &__header {
    margin-bottom: 2px;
    padding: 18px 20px;
    background-color: var(--color-constant-greyscale-600);
    font-weight: 400;
    font-size: 17px;
    line-height: 24px;
  }

  &__content {
    padding: 18px 20px;
    background-color: var(--color-constant-greyscale-800);
  }

  &__item {
    font-weight: 400;
    font-size: 17px;
    line-height: 24px;

    &:not(:last-child) {
      margin-bottom: 8px;
    }
  }
}
</style>
