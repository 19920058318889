export default {
  SET_AUTH_USER(state, token) {
    state.token = token;
    state.isAuth = true;
  },
  LOGOUT_USER(state) {
    state.token = null;
    state.isAuth = false;
  },
  SET_USER(state, user) {
    state.user = user;
  },
};
