const splitUrl = (url) => {
  //eslint-disable-next-line
  const reg = /([^\/\:]+):\/\/([^\/]+):([0-9]+)/;

  const type = url.includes('srt://') ? 'srt' : 'rtmp';
  const string =
    type === 'srt'
      ? url.replace(reg, '$3').includes('/')
        ? url
        : `${url}/`
      : url;
  return string.split('/').reduceRight((A, e, i) => {
    if (A === null)
      return { [type === 'srt' ? 'stream_id' : 'key']: e, url: [] };
    A.url.unshift(e);
    if (i === 0) A.url = A.url.join('/');
    return A;
  }, null);
};

const copy = (value) => {
  navigator.clipboard.writeText(value);
};

export { splitUrl, copy };
