import axios from 'axios';

const generateProject = (state, destinations) => {
  return {
    ppt_config: {
      sources: [state.input.stream_id],
      destinations,
    },
    ppt_desc: state.project?.ppt_desc || 'restream',
  };
};

export default {
  async GET_INPUT(
    { commit },
    { format, password, srtLatency, lookup, streamId }
  ) {
    try {
      let params;
      if (format !== 'rtmp') {
        params = {
          name: streamId === '' ? null : streamId,
          passwd: password,
          latency: srtLatency === '' ? null : srtLatency,
        };

        Object.keys(params).forEach((key) => {
          if (params[key] === null) delete params[key];
        });
      }

      const { data } = await axios.post('/api/user/requestInput', {
        format: format,
        ident: 'restream',
        lookup,
        srt: params,
      });

      if (data) {
        commit('SET_INPUT', { format, ...data });
        return data;
      } else {
        const { data } = await axios.post('/api/user/requestInput', {
          format: format,
          ident: 'restream',
          srt: params,
        });

        commit('SET_INPUT', { format, ...data });
        return data;
      }
    } catch (error) {
      return error;
    }
  },

  async GET_STATUS_INPUT({ commit, state }) {
    const { data } = await axios.get(`/api/stream/${state.input?.stream_id}`);
    commit('SET_INPUT_STATUS', data);
  },

  async GET_STATUS_OUTPUT({ commit, state }) {
    try {
      if (!state.project) return;
      const { data } = await axios.get(
        `/api/projectStreams/${state.project?.ppt_id}`
      );
      commit('SET_OUTPUT_STATUS', data);
      commit('SET_INPUT_STATUS', data.inputs[0]);
      return data;
    } catch (error) {
      return error;
    }
  },

  async GET_OUTPUT({ commit, state }) {
    try {
      if (!state.project) return [];

      const destinations = state.project.ppt_config.destinations.map((item) => {
        return {
          ...item,
          url: item.url,
          description: item.description,
          // output_enable: !item.disabled,
          restart: item.restart,
        };
      });
      commit('SET_OUTPUTS', destinations);
    } catch (error) {
      return error;
    }
  },
  async START_OUTPUT_STREAM({ commit, state }, index) {
    try {
      const destinations = [...state.outputs];
      destinations[index].disabled = false;
      const changedProject = generateProject(state, destinations);

      await axios.post(`/api/project/${state.project.ppt_id}`, changedProject);
      commit('SET_OUTPUTS', destinations);
      return;
    } catch (error) {
      return error;
    }
  },
  async STOP_OUTPUT_STREAM({ commit, state }, index) {
    try {
      const destinations = [...state.outputs];
      destinations[index].disabled = true;
      const changedProject = generateProject(state, destinations);

      await axios.post(`/api/project/${state.project.ppt_id}`, changedProject);
      commit('SET_OUTPUTS', destinations);
      return;
    } catch (error) {
      return error;
    }
  },
  async ADD_NEW_OUTPUT({ commit, state }, output) {
    try {
      let destinations = [...state.outputs];
      destinations.push(output);
      const changedProject = generateProject(state, destinations);

      if (!state.project) {
        const { data } = await axios.post(
          '/api/project/new?flag=true',
          changedProject
        );
        commit('SET_PROJECT', data);
        destinations = data.ppt_config.destinations;
      } else {
        const { data } = await axios.post(
          `/api/project/${state.project.ppt_id}?flag=true`,
          changedProject
        );
        destinations = data.ppt_config.destinations;
      }

      commit('SET_OUTPUTS', destinations);
      return;
    } catch (error) {
      return error;
    }
  },
  async SAVE_OUTPUT({ commit, state }, { output, index = null } = {}) {
    try {
      const destinations = [...state.outputs];
      if (index !== null) destinations.splice(index, 1, output);
      const changedProject = generateProject(state, destinations);

      await axios.post(`/api/project/${state.project.ppt_id}`, changedProject);
      commit('SET_OUTPUTS', destinations);
      return;
    } catch (error) {
      return error;
    }
  },

  async CLEAR_INPUT({ commit }) {
    commit('CLEAR_INPUT');
  },

  async DELETE_OUTPUT({ commit, state }, index) {
    try {
      const destinations = [...state.outputs];
      destinations.splice(index, 1);
      const changedProject = generateProject(state, destinations);
      await axios.post(`/api/project/${state.project.ppt_id}`, changedProject);
      commit('SET_OUTPUTS', destinations);
    } catch (error) {
      return error;
    }
  },

  async GET_PROJECT({ commit }) {
    try {
      const { data } = await axios.get('/api/projects/search-type/=streamer');
      if (!data.length) return null;
      const { data: project } = await axios.get(
        `/api/project/${data[0].ppt_id}`
      );
      commit(
        'SET_PROJECT',
        { ppt_id: data[0].ppt_id, ppt_desc: data[0].ppt_desc, ...project } ||
          null
      );

      const format = project.ppt_config.sources[0].stream_url.includes('srt://')
        ? 'srt'
        : 'rtmp';

      commit('SET_INPUT', {
        format,
        ...project.ppt_config.sources[0],
        status: project.ppt_config.sources[0],
      });
      return { format, ...project, status: project.ppt_config.sources[0] };
    } catch (error) {
      return error;
    }
  },
  CHANGE_IS_SAVED_INPUT({ commit }, data) {
    commit('SET_IS_SAVE_INPUT', data);
  },
};
