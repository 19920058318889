import VueRouter from 'vue-router';
import SettingsPage from '@/views/SettingsPage.vue';
import MixerPage from '@/views/MixerPage.vue';

// adminiistration
import AdministrationPage from '@/views/administration/AdministrationPage.vue';
import UserPage from '@/views/administration/UserPage.vue';
import NewProject from '@/views/settings/NewProject.vue';
import OpenProject from '@/views/settings/OpenProject.vue';
import Landing from '@/views/Landing.vue';
// import Editor from '@/views/Editor.vue';

// mixer pages
// import Statistics from '@/views/statistics';
// import StatisticsCurrentPage from '@/views/statistics/id.vue';
// import StatisticCommon from '@/views/statistics/common.vue';

// restream
import RestreamPage from '@/views/RestreamPage.vue';

import LoginMix from '@/views/LoginMix.vue';
import store from '@/store';
// import axios from 'axios';

import ErrorPage from '@/views/Error.vue';

/**
 * @router
 * fields:
 * @breadcrumbs - show navigagion. Example:
 * meta: {
 *   breadcrumbs: [
 *     { name: 'CloudMix', link: '/'},
 *     { name: 'secondPage', link: '/'},
 *   ]
 * }
 */
const routes = [
  {
    name: 'cloudmix',
    path: '/cloudmix',
    component: SettingsPage,
    meta: {
      title: 'Облачный видеопродакшн',
      layout: 'common-layout',
    },
    children: [
      {
        name: 'new',
        path: '/cloudmix/new',
        component: NewProject,
        meta: {
          breadcrumbs: [{ name: 'CloudMix', link: '/' }],
          layout: 'common-layout',
        },
      },
      {
        name: 'open',
        path: '/cloudmix/open',
        component: OpenProject,
        meta: {
          breadcrumbs: [{ name: 'CloudMix', link: '/cloudmix' }],
          layout: 'common-layout',
        },
      },
    ],
  },
  {
    path: '/login',
    component: LoginMix,
    meta: {
      layout: 'empty-layout',
    },
  },
  // {
  //   path: '/editor',
  //   component: Editor,
  //   meta: {
  //     title: '',
  //     layout: 'common-layout',
  //   },
  // },
  {
    path: '/administration',
    component: AdministrationPage,
    meta: {
      breadcrumbs: [{ name: 'CloudMix', link: '/cloudmix' }],
    },
  },
  {
    path: '/administration/user',
    component: UserPage,
    meta: {
      breadcrumbs: [
        { name: 'CloudMix', link: '/cloudmix' },
        { name: 'Administration', link: '/administration' },
      ],
    },
  },
  {
    name: 'MixerPage',
    path: '/mixer-page',
    component: MixerPage,
    meta: {
      layout: 'default-layout',
    },
  },
  // {
  //   name: 'Statistics',
  //   path: '/statistics',
  //   component: Statistics,
  //   meta: {
  //     layout: 'common-layout',
  //     title: 'Статистика',
  //   },
  //   children: [
  //     {
  //       name: 'StatisticsCommon',
  //       path: '/statistics/common',
  //       component: StatisticCommon,
  //       meta: {
  //         layout: 'common-layout',
  //         title: 'Статистика',
  //       },
  //     },
  //     {
  //       name: 'StatisticsCurrentPage',
  //       path: '/statistics/:id',
  //       component: StatisticsCurrentPage,
  //       meta: {
  //         layout: 'common-layout',
  //         title: 'Статистика',
  //       },
  //     },
  //   ],
  // },

  {
    path: '/error',
    component: ErrorPage,
    meta: {
      layout: 'error-layout',
    },
  },

  {
    path: '/restream',
    component: RestreamPage,
    meta: {
      title: 'Рестрим студия',
      layout: 'common-layout',
    },
  },
  {
    path: '/landing',
    component: Landing,
    meta: {
      layout: 'empty-layout',
    },
  },
];

const router = new VueRouter({
  mode: 'history',
  routes,
});

router.beforeEach(async (to, from, next) => {
  if (to.meta.layout === 'error-layout') return next();
  if (store.state.auth.isAuth) {
    try {
      await store.dispatch('auth/GET_USER');
    } catch (error) {
      store.dispatch('auth/LOGOUT_USER');
      return next('/landing');
    }
  }

  if (
    store.state.auth.isAuth ||
    to.path === '/login' ||
    to.path === '/api/login'
  ) {
    if (to.path === '/statistics') {
      return next('/statistics/common');
    } else if (to.path === '/') {
      return next('/cloudmix');
    } else if (from.path === '/mixer-page' && to.path.includes('/cloudmix/')) {
      return next('/cloudmix');
    } else {
      return next();
    }
  }
  if (to.path !== '/landing') return next('/landing');
  return next();
});

export default router;
