var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:[
    'restream-output-card',
    {
      'restream-output-card_disabled': !_vm.isSavedInput && _vm.project,
    },
  ]},[_c('div',{staticClass:"restream-output-card__title"},[_vm._v("Выход")]),_c('div',{staticClass:"restream-output-card__list"},_vm._l((_vm.listOutputs),function(item,index){return _c('div',{key:item.output_id,staticClass:"restream-output-card__item"},[_c('div',{staticClass:"restream-output-card__item-content"},[_c('div',{class:[
            'restream-output-card__item-status',
            {
              'restream-output-card__item-status_stream': item?.online,
            },
          ]}),_c('div',{staticClass:"restream-output-card__item-name"},[_vm._v(" "+_vm._s(item.description)+" ")])]),_c('div',{staticClass:"restream-output-card__item-controls"},[_c('IconMix',{staticClass:"button-icon restream-output-card__item-icon-delete",attrs:{"icon":"button-delete","disabled":!item.disabled},nativeOn:{"click":function($event){return _vm.deleteOutput(item.output_id)}}}),_c('IconMix',{staticClass:"button-icon restream-output-card__item-icon-settings",attrs:{"icon":"settings"},nativeOn:{"click":function($event){return _vm.changeOutput(item, index)}}}),_c('SwitcherMix',{attrs:{"value":!item.disabled},on:{"input":(value) => {
              _vm.startStopStream(value, index);
            }}})],1)])}),0),_c('MixButton',{staticClass:"restream-output-card__button-add",attrs:{"size":"medium","type":"secondary","disabled":!_vm.store.state.restream.input},on:{"click":function($event){_vm.isShowRestreamModal = true}}},[_c('IconMix',{staticClass:"restream-output-card__button-icon",attrs:{"icon":"button-plus"}}),_c('span',[_vm._v("Добавить")])],1),_c('ModalMix',{attrs:{"title":"Выход","size":"small","isOpen":_vm.isShowRestreamModal},on:{"close":_vm.closeModal}},[_c('RestreamOutputForm',{attrs:{"currentChangingOutput":_vm.currentChangingOutput,"currentIndex":_vm.currentIndex},on:{"close":_vm.closeModal}})],1),(!_vm.store.state.restream.input)?_c('div',{staticClass:"restream-output-card__hint"},[_vm._v(" Для добавления выхода необходимо выбрать вход ")]):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }