import Vue from 'vue';
import store from './store';
import router from './router';
import { MtsVue } from '@mts-ds/components-vue2';
import App from './App.vue';
import VueRouter from 'vue-router';
import '@/assets/styles/base.scss';
// import '@mts-ds/base/build/styles/main.css';
import axios from '@/plugins/axios.js';
import VueMask from 'v-mask';
import DefaultLayout from '@/layouts/Default.vue';
import CommonLayout from '@/layouts/CommonLayout.vue';

import ErrorLayout from '@/layouts/Error.vue';
import EmptyLayout from '@/layouts/EmptyLayout.vue';

import Toast from 'vue-toastification';
// Import the CSS or use your own!
import 'vue-toastification/dist/index.css';

const options = {
  // You can set your default options here
  toastClassName: 'toast-container',
  bodyClassName: ['toast-body'],
  hideProgressBar: true,
  closeButton: false,
  //eslint-disable-next-line
  icon: require('@/assets/icons/error.svg?inline'),
};
axios();

// eslint-disable-next-line
console.log('version', process.env.VUE_APP_GIT_HASH);

Vue.use(VueRouter);
Vue.use(MtsVue);
Vue.use(VueMask);
Vue.use(Toast, options);

Vue.component('default-layout', DefaultLayout);
Vue.component('common-layout', CommonLayout);
Vue.component('error-layout', ErrorLayout);
Vue.component('empty-layout', EmptyLayout);
new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
