<template>
  <div class="restream-input-card">
    <div
      :class="[
        'restream-input-card__header',
        {
          'restream-input-card__header_opening': isOpenInput,
        },
      ]"
      @click="isOpenInput = !isOpenInput"
    >
      <div class="restream-input-card__flex">
        <div
          :class="`restream-input-card__header__status restream-input-card__header__status_${statusInput}`"
        />
        <div class="restream-input-card__title">Вход</div>
      </div>
      <IconMix icon="carret" />
    </div>
    <div class="restream-input-card__content" v-if="isOpenInput">
      <SelectMix
        class="restream-input-card__form-field"
        v-model="format"
        :options="options"
        @input="getInput({ lookup: true })"
        labelSelect="name"
        size="xs"
        :allowEmpty="false"
      />
      <template v-if="format?.value === 'srt'">
        <div class="restream-input-card__input">
          <InputMix
            class="restream-input-card__form-field"
            v-model="formSrt.hostName"
            label="Host name"
            disabled
            size="xs"
          />
          <CopyButton :value="formSrt.hostName" />
        </div>

        <div class="restream-input-card__flex-container">
          <InputMix
            class="restream-input-card__form-field"
            v-model="formSrt.port"
            label="Port"
            size="xs"
            disabled
          />
          <InputMix
            class="restream-input-card__form-field"
            v-model="formSrt.srtLatency"
            label="Latency"
            :error="errorsList.password?.srtLatency"
            size="xs"
            @input="validateForm"
            :maxValue="5000"
          />
        </div>

        <div class="restream-input-card__input">
          <InputMix
            class="restream-input-card__form-field"
            v-model="formSrt.streamId"
            :error="errorsList.password?.streamId"
            label="Stream ID"
            :hidden="true"
            @input="validateForm"
            size="xs"
          />
          <CopyButton :value="formSrt.streamId" />
        </div>
        <div class="restream-input-card__input">
          <InputMix
            class="restream-input-card__form-field"
            v-model="formSrt.password"
            :error="errorsList.password?.message"
            label="Passphrase"
            @input="validateForm"
            :hidden="true"
            size="xs"
          />
          <CopyButton :value="formSrt.password" />
        </div>
      </template>
      <template v-else>
        <div class="restream-input-card__input">
          <InputMix
            class="restream-input-card__form-field"
            v-model="formRtmp.url"
            label="URL сервера"
            disabled
            size="xs"
          />
          <CopyButton :value="formRtmp.url" />
        </div>
        <div class="restream-input-card__input">
          <InputMix
            class="restream-input-card__form-field"
            v-model="formRtmp.key"
            label="Ключ трансляции"
            :hidden="true"
            disabled
            size="xs"
          />
          <CopyButton :value="formRtmp.key" />
        </div>
      </template>
      <MixButton
        size="medium"
        type="primary"
        class="restream-input-card__button-save"
        @click="changeForm"
        :disabled="isDisabledSave"
      >
        <span>Сохранить</span>
      </MixButton>
      <div class="restream-input-card__error" v-if="!isSavedInput && project">
        Сохраните настройки входа
      </div>
    </div>
  </div>
</template>

<script>
import {
  computed,
  getCurrentInstance,
  onBeforeUnmount,
  onMounted,
  ref,
} from 'vue';
import InputMix from '@core/src/components/common/InputMix.vue';
import SelectMix from '@core/src/components/common/SelectMix.vue';
import IconMix from '@core/src/components/common/icon/IconMix.vue';
import Joi from 'joi';
import { splitUrl } from '@/helpers/common.js';
import MixButton from '@core/src/components/common/buttons/MixButton.vue';
import CopyButton from '@core/src/components/common/buttons/CopyButton.vue';

export default {
  name: 'RestreamInputCard',
  components: {
    InputMix,
    SelectMix,
    IconMix,
    MixButton,
    CopyButton,
  },
  props: {},
  setup() {
    const {
      proxy: { $store: store },
    } = getCurrentInstance();

    const isOpenInput = ref(true);
    const intervalCheckStatus = ref(null);
    const format = ref({ name: 'RTMP', value: 'rtmp' });
    const errorsList = ref([]);
    const options = ref([
      { name: 'RTMP', value: 'rtmp' },
      { name: 'SRT', value: 'srt' },
    ]);

    onMounted(async () => {
      const data = await store.dispatch('restream/GET_PROJECT');
      if (!data) {
        await getRtmp({ lookup: true });
      } else {
        format.value = options.value.find((item) => item.value === data.format);
        if (data.format === 'srt') setSrt(store.state.restream.input);
        else setRtmp(store.state.restream.input);
      }
      await store.dispatch('restream/GET_OUTPUT');
      await store.dispatch('restream/GET_STATUS_OUTPUT');

      if (!store.state.restream.project)
        store.dispatch('restream/GET_STATUS_INPUT');

      intervalCheckStatus.value = setInterval(() => {
        if (store.state.restream.project) {
          clearInterval(intervalCheckStatus.value);
          return;
        }

        store.dispatch('restream/GET_STATUS_INPUT');
      }, 10000);
    });

    onBeforeUnmount(() => {
      clearInterval(intervalCheckStatus.value);
    });

    const formSrt = ref({
      hostName: '',
      port: '',
      srtLatency: '',
      streamId: '',
      password: '',
    });

    const setSrt = async (data) => {
      formSrt.value = {
        hostName: data.stream_url,
        port: data.stream_url_opt.port,
        srtLatency: data.stream_url_opt.srtLatency,
        streamId: data.stream_url_opt.srtStreamId,
        password: data.stream_url_opt.srtPassphrase,
      };
    };

    const getSrt = async (params) => {
      const data = await store.dispatch('restream/GET_INPUT', {
        ...formSrt.value,
        format: format.value.value,
        ...params,
      });

      if (params.lookup) {
        setSrt(data);
      }
    };

    const formRtmp = ref({
      url: '',
      key: '',
    });

    const setRtmp = async (data) => {
      formRtmp.value = splitUrl(data.stream_url);
    };

    const getRtmp = async (params) => {
      const data = await store.dispatch('restream/GET_INPUT', {
        ...formRtmp.value,
        format: format.value.value,
        ...params,
      });

      if (params.lookup) {
        setRtmp(data);
      }
    };

    const getInput = async (params = { lookup: false }) => {
      if (format?.value?.value === 'rtmp') {
        await getRtmp(params);
      } else if (format?.value?.value === 'srt') {
        await getSrt(params);
      }

      // const currentSelectedFormat = store.state.restream.project.
      store.dispatch(
        'restream/CHANGE_IS_SAVED_INPUT',
        !store.state.restream.isSavedInput
      );
    };

    const changeForm = async () => {
      if (format.value?.value === 'srt') {
        validateForm();
        if (Object.keys(errorsList.value).length > 0) return;
      }

      await getInput();
      await store.dispatch('restream/SAVE_OUTPUT');
      store.dispatch('restream/CHANGE_IS_SAVED_INPUT', true);
    };

    const schema = computed(() => {
      if (format.value?.value === 'srt') {
        return Joi.object().keys({
          srtLatency: Joi.number().required(),
          streamId: Joi.string().required().allow(''),
          password: Joi.string().min(10).max(80).allow(''),
        });
      } else return null;
    });

    const validateForm = () => {
      const options = {
        messages: {
          'string.empty': 'Обязательное поле',
        },
        abortEarly: false,
        stripUnknown: true,
      };

      const { error: { details = [] } = {} } = schema.value.validate(
        formSrt.value,
        options
      );

      errorsList.value = details.reduce((acc, current) => {
        acc[current.context.key] = {
          message: current.message,
          type: current.type,
        };
        return acc;
      }, {});
    };

    const statusInput = computed(() => {
      if (!store.state.restream.input?.status?.ostream_rx_status)
        return 'empty';
      else if (store.state.restream.input?.status?.ostream_rx_status)
        return 'success';
      return 'error';
    });

    const isSavedInput = computed(() => {
      return store.state.restream.isSavedInput;
    });

    const project = computed(() => {
      return store.state.restream.project;
    });

    const input = computed(() => store.state.restream.input);

    const isDisabledSave = computed(() => {
      if (!isSavedInput.value && project.value) return false;
      if (format.value.value === 'srt') {
        if (
          +formSrt.value.srtLatency !==
            input.value.stream_url_opt?.srtLatency ||
          formSrt.value.streamId !== input.value.stream_url_opt?.srtStreamId ||
          formSrt.value.password !== input.value.stream_url_opt?.srtPassphrase
        )
          return false;
      }
      return true;
    });

    return {
      isOpenInput,
      options,
      changeForm,
      formSrt,
      format,
      formRtmp,
      errorsList,
      statusInput,
      validateForm,
      getInput,
      isSavedInput,
      project,
      isDisabledSave,
      input,
    };
  },
};
</script>

<style lang="scss" scoped>
.restream-input-card {
  border-radius: 12px;
  background-color: $color-background-primary-elevated;
  padding: 16px;

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;

    &_opening {
      margin-bottom: 24px;
    }

    &__status {
      margin-right: 8px;
      width: 8px;
      height: 8px;
      border-radius: 50%;

      &_error {
        background-color: red;
      }

      &_empty {
        background-color: $color-icon-tertiary;
      }

      &_success {
        background-color: $color-accent-positive;
      }
    }
  }

  &__title {
    font-family: 'MTS Compact';
    font-style: normal;
    font-weight: 400;
    font-size: 17px;
    line-height: 24px;
  }

  &__form-field {
    margin-bottom: 20px;
  }

  &__input {
    display: flex;
    align-items: center;
  }

  &__flex {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__flex-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 12px;
  }

  &__button-save {
    width: 100%;
    margin-bottom: 6px;
  }

  &__error {
    font-family: 'MTS Compact';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    display: flex;
    align-items: center;
    text-align: center;
    color: $color-brand;
  }
}
</style>
