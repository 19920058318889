import axios from 'axios';
import store from '@/store';
import Vue from 'vue';

export default function setup() {
  // axios.defaults.baseURL = 'https://demo1.mvp.ipvmix.ru';
  // axios.defaults.baseURL = 'https://demo.mvp.ipvmix.ru:443';

  axios.interceptors.request.use(
    function (config) {
      const token = store.state.auth.token;
      if (token) {
        config.headers.Authorization = `Bearer ${token}`;
      }
      return config;
    },
    function (err) {
      return Promise.reject(err);
    }
  );
  axios.interceptors.response.use(
    function (response) {
      return response;
    },
    function (error) {
      Vue.$toast(
        'Что-то пошло не так.\nПопробуйте ещё раз или \nобратитесь в поддержку.',
        {
          timeout: 5000,
          type: 'error',
        }
      );
      return Promise.reject(error);
    }
  );
}
