<template>
  <div class="project-controls__container">
    <StatusProject
      class="project-controls__button"
      :status="status"
      @click="openMixerCore"
    />
    <MixButton
      class="project-controls__button"
      type="icon"
      icon="button-edit"
      @click="showEditProject"
    />
    <div class="project-controls__button" v-click-outside="hide">
      <MixButton
        type="icon"
        icon="button-delete"
        @click="isShowControlsDelete = true"
      />
      <!--  -->
      <div
        v-if="isShowControlsDelete"
        class="project-controls__delete-controls"
      >
        <span>Delete</span>
        <MixButton
          class="project-controls__button-delete"
          @click="deleteProject"
          >Yes</MixButton
        >
        <MixButton
          class="project-controls__button-delete"
          @click="isShowControlsDelete = false"
          >No</MixButton
        >
      </div>
    </div>

    <MixButton
      v-if="status === 0"
      class="project-controls__button"
      type="icon"
      icon="button-play"
      @click="startPipeline"
      :isLoading="isLoading"
    />
    <MixButton
      v-else
      class="project-controls__button"
      type="icon"
      icon="button-pause"
      state="second"
      @click="stopPipeline"
    />

    <ModalMix :isOpen="isShowEditModal" @close="hideEditModal" size="large">
      <EditProjectForm
        :pipeline="jsonPipeline"
        @close="hideEditModal"
        :projectId="projectId"
      />
    </ModalMix>
  </div>
</template>

<script>
import { getCurrentInstance, ref } from 'vue';
import StatusProject from '@/components/common/StatusProject.vue';
import MixButton from '@core/src/components/common/buttons/MixButton.vue';
import ClickOutside from 'vue-click-outside';
import ModalMix from '@/components/modals/ModalMix.vue';
import EditProjectForm from '@/components/forms/EditProjectForm.vue';
import axios from 'axios';
import { useRouter } from 'vue-router/composables';

export default {
  directives: {
    ClickOutside,
  },
  components: { StatusProject, MixButton, ModalMix, EditProjectForm },
  props: {
    status: {
      type: Number,
    },
    projectId: {
      type: String,
    },
    mixerId: {
      type: String,
    },
  },
  setup(props, { emit }) {
    const isShowControlsDelete = ref(false);
    const { proxy } = getCurrentInstance();
    const store = proxy.$store;
    const isLoading = ref(false);
    const router = useRouter();

    const hide = () => {
      if (!isShowControlsDelete.value) return;
      isShowControlsDelete.value = false;
    };

    const deleteProject = () => {
      emit('deleteProject');
      isShowControlsDelete.value = false;
    };

    const isShowEditModal = ref(false);

    const jsonPipeline = ref('');

    const showEditProject = async () => {
      const { data } = await axios.get(
        `/api/admin/pipelineSetup/${props.projectId}`
      );
      jsonPipeline.value = data;
      isShowEditModal.value = true;
      emit('showHideModal', isShowEditModal.value);
    };

    const hideEditModal = () => {
      isShowEditModal.value = false;
      emit('showHideModal', isShowEditModal.value);
    };

    const startPipeline = async () => {
      try {
        isLoading.value = true;
        await store.dispatch('admin/START_PIPELINE', {
          project_id: props.projectId,
          user_id: store.state.admin.user.user_id,
        });

        isLoading.value = false;
      } catch (error) {
        isLoading.value = false;
      }
    };

    const stopPipeline = async () => {
      try {
        isLoading.value = true;
        await store.dispatch('admin/STOP_PIPELINE', {
          project_id: props.projectId,
          user_id: store.state.admin.user.user_id,
        });
        isLoading.value = false;
      } catch (error) {
        isLoading.value = false;
      }
    };

    const openMixerCore = async () => {
      router.push({
        name: 'MixerPage',
        query: { id: props.mixerId, type: 'admin' },
      });
    };

    return {
      isShowControlsDelete,
      hide,
      deleteProject,
      isShowEditModal,
      showEditProject,
      jsonPipeline,
      startPipeline,
      stopPipeline,
      isLoading,
      openMixerCore,
      hideEditModal,
    };
  },
};
</script>

<style lang="scss" scoped>
.project-controls {
  &__container {
    display: flex;
  }

  &__button {
    position: relative;

    &:not(:last-child) {
      margin-right: 18px;
    }

    &:first-child {
      margin-right: 36px;
    }
  }

  &__delete-controls {
    position: absolute;
    background-color: var(--color-background-inverted);
    height: 40px;
    width: 200px;
    right: 0;
    z-index: 9;
    box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.12),
      0px 12px 20px rgba(0, 0, 0, 0.14);
    border-radius: 6px;
    top: 50%;
    transform: translateY(-50%);
    display: flex;
    align-items: center;
    color: var(--color-text-inverted);
    padding: 10px 4px 10px 16px;

    span {
      display: block;
      margin-right: 19px;
    }
  }

  &__button-delete {
    width: 58px;

    &:last-child {
      margin-left: 4px;
    }
  }
}
</style>
