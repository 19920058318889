<template>
  <div class="landing">
    <Logo class="landing__logo" viewBox="0 0  760 89" />
    <div class="landing__description">
      Облачный сервис для онлайн видео продакшена
    </div>
    <MixButton
      @click="goLogin"
      class="landing__button"
      type="primary"
      size="extra-large"
    >
      Попробовать
    </MixButton>
    <div class="landing__help">
      Для комфортной работы, пожалуйста используйте разрешение 1920х1080 <br />
      На этапе бета-тестирования зарегистрироваться можно только через Google
      Auth
    </div>
    <div class="landing__copyright">© 2023 ПАО «МТС» Все права защищены.</div>
  </div>
</template>

<script>
import Logo from '@/assets/icons/LogoBig.svg?inline';
import MixButton from '@core/src/components/common/buttons/MixButton.vue';
import { useRouter } from 'vue-router/composables';

export default {
  components: { Logo, MixButton },
  setup() {
    const router = useRouter();

    const goLogin = () => {
      router.push('/login');
    };
    return {
      goLogin,
    };
  },
};
</script>

<style lang="scss" scoped>
.landing {
  background-image: url('@/assets/images/landingBg.png');
  background-size: cover;
  background-repeat: no-repeat;
  min-height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 90px;
  padding-bottom: 20px;

  @include at('large') {
    padding-top: 200px;
  }

  @include at('xlarge') {
    padding-top: 290px;
  }

  &__logo {
    width: 360px;
    height: 89px;
    margin-bottom: 37px;
    min-width: 300px;
    min-height: 89px;

    @include at('xlarge') {
      width: 760px;
    }
  }

  &__description {
    margin-bottom: 56px;
    font-family: 'MTS Wide';
    font-style: normal;
    font-weight: 500;
    font-size: 32px;
    line-height: 36px;
    text-align: center;

    @include at('middle') {
      margin-bottom: 136px;
    }
  }

  &__button {
    margin-bottom: 44px;
    width: 335px;

    @include at('middle') {
      margin-bottom: 144px;
    }
  }

  &__help {
    margin-bottom: 100px;
    font-family: 'MTS Compact';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 28px;
    text-align: center;
    max-width: 799px;
  }

  &__copyright {
    font-family: 'MTS Compact';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    display: flex;
    align-items: center;
    text-align: right;
    text-transform: uppercase;
    color: #969fa8;
  }
}
</style>
