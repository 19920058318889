<template>
  <div class="app">
    <slot />
  </div>
</template>

<script>
export default {
  components: {},
  setup() {},
};
</script>

<style lang="scss" scoped>
.app {
}
</style>
