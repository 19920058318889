<template>
  <div class="edit-project-form">
    <div class="edit-project-form__title">Project editor</div>
    <!-- <MtsTextarea v-model="value" class="edit-project-form__textarea" /> -->
    <textarea v-model="value" class="edit-project-form__textarea" />
    <div class="edit-project-form__controls">
      <MixButton
        size="medium"
        class="edit-project-form__button edit-project-form__button_cancel"
        type="secondary"
        @click="$emit('close')"
      >
        Cancel
      </MixButton>
      <MixButton class="edit-project-form__button" size="medium" @click="save">
        Save
      </MixButton>
    </div>
  </div>
</template>

<script>
// import { MtsTextarea } from '@mts-ds/components-vue2';
import { onMounted, ref } from 'vue';
import MixButton from '@core/src/components/common/buttons/MixButton.vue';
import axios from 'axios';

export default {
  components: {
    // MtsTextarea,
    MixButton,
  },
  props: {
    pipeline: {
      type: Object,
    },
    projectId: {
      type: String,
    },
  },
  setup(props, { emit }) {
    const value = ref(null);

    onMounted(() => {
      value.value = JSON.stringify(props.pipeline, undefined, 4);
    });

    const save = async () => {
      await axios.post(
        `/api/admin/pipelineSetup/${props.projectId}`,
        JSON.parse(value.value)
      );
      emit('close');
    };

    return {
      value,
      save,
    };
  },
};
</script>

<style lang="scss">
.edit-project-form {
  position: relative;
  height: 80vh;
  &__controls {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  &__title {
    margin-bottom: 32px;
    font-weight: 500;
    font-size: 24px;
    line-height: 28px;
  }

  &__button {
    width: 128px;

    &_cancel {
      margin-right: 12px;
    }
  }

  &__textarea {
    margin-bottom: 32px;
    height: 77%;
    background-color: #1d2023;
    border: 2px solid #626c77;
    border-radius: 10px;
    width: 100%;
    color: #fff;
    padding: 18px;
    font-family: Courier;
    outline: none;
    font-size: 16px;
  }
}
</style>
