<template>
  <div></div>
</template>

<script>
import axios from 'axios';
import { getCurrentInstance } from 'vue';

export default {
  setup() {
    const { proxy } = getCurrentInstance();
    const store = proxy.$store;
    const router = proxy.$router;

    const {
      query: { token },
    } = proxy.$route;

    const checkUser = async () => {
      if (token) {
        try {
          store.dispatch('auth/AUTH_USER', token);
          await axios.get('/api/whoami');
          router.push('/cloudmix');
        } catch (error) {
          store.dispatch('auth/LOGOUT_USER');
          window.location.replace('/api/login/google');
        }
      } else if (!store.state.auth.isAuth) {
        window.location.replace('/api/login/google');
      } else if (store.state.auth.isAuth) {
        router.push('/cloudmix');
      }
    };

    checkUser();
    return {};
  },
};
</script>
