<template>
  <div class="user-setting-table">
    <TableMix
      :tableData="tableUserSettings"
      :widthControls="102"
      :rowHeight="40"
      class="user-setting-table__table"
      :bodyHeight="262"
    >
      <template #head-controls> </template>
      <template
        #row-controls="{
          setting,
          value,
          projectId,
          index,
          project,
          projectObj,
        }"
      >
        <UserSettingControls
          :params="{ setting, value, projectId, index, project, projectObj }"
        />
      </template>
    </TableMix>
    <div class="user-setting-table__footer">
      <MixButton @click="isShowEditModal = true">
        <IconMix class="button__icon" icon="button-plus" />
        <span>Add settings</span>
      </MixButton>
    </div>

    <ModalMix
      size="small"
      :isOpen="isShowEditModal"
      @close="isShowEditModal = false"
    >
      <UserSettingEditForm
        type="add"
        @close="isShowEditModal = false"
        :info="params"
      />
    </ModalMix>
  </div>
</template>

<script>
import TableMix from '@/components/common/TableMix.vue';
import { toRefs, ref, getCurrentInstance, watch } from 'vue';
import UserSettingControls from './controls/UserSettingControls.vue';
import MixButton from '@core/src/components/common/buttons/MixButton.vue';
import IconMix from '@core/src/components/common/icon/IconMix.vue';
import ModalMix from '@/components/modals/ModalMix.vue';
import UserSettingEditForm from '@/components/forms/UserSettingEditForm.vue';

export default {
  components: {
    TableMix,
    UserSettingControls,
    MixButton,
    IconMix,
    ModalMix,
    UserSettingEditForm,
  },
  props: {
    tableData: {
      default: () => [],
      type: Array,
    },
  },
  setup(props) {
    const {
      proxy: { $store: store },
    } = getCurrentInstance();

    const { tableData } = toRefs(props);

    const tableUserSettings = ref({});

    watch(tableData, () => {
      mapTableData();
    });

    const mapTableData = () => {
      tableUserSettings.value = {
        head: ['setting', 'value', 'project'],
        rows: tableData.value.map((item) => {
          return {
            setting: item.key,
            value: item.value,
            project: item.project.ppt_desc || 'All',
            projectObj: item.project,
            projectId: item.ppt_id,
          };
        }),
      };
    };

    mapTableData();

    store.dispatch('folder/GET_FOLDERS');

    const isShowEditModal = ref(false);

    const params = ref({
      projectId: null,
      setting: null,
    });
    return {
      tableUserSettings,
      isShowEditModal,
      params,
    };
  },
};
</script>

<style lang="scss" scoped>
.user-setting-table {
  &__footer {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px 0;
    background-color: var(--color-constant-greyscale-800);
    border-radius: 0 0 8px 8px;
    margin-top: 2px;
  }

  &__table {
    border-radius: 8px 8px 0 0;
  }

  .button {
    width: 246px;

    &__icon {
      margin-right: 7px;
    }
  }
}
</style>
