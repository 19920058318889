<template>
  <div class="error">
    <div class="error__card">
      <div class="error__title">Error</div>
      <div class="error__description">Упс, что-то пошло не так</div>
      <MixButton size="medium" class="error__button" @click="goHome">
        Попробовать еще раз</MixButton
      >
    </div>
  </div>
</template>

<script>
import MixButton from '@core/src/components/common/buttons/MixButton.vue';
import { useRouter } from 'vue-router/composables';

export default {
  setup() {
    const router = useRouter();

    const goHome = () => {
      router.push('/');
    };
    return {
      goHome,
    };
  },
  components: { MixButton },
};
</script>

<style lang="scss" scoped>
.error {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;

  &__card {
    background-color: var(--color-background-modal);
    width: 280px;
    // height: 172px;
    border-radius: 16px;
    padding: 14px 30px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  &__title {
    color: #eb4a13;
    font-weight: 500;
    font-size: 24px;
    line-height: 28px;
    margin-bottom: 4px;
  }

  &__description {
    font-weight: 400;
    font-size: 17px;
    line-height: 24px;
    margin-bottom: 24px;
  }

  &__button {
    width: 100%;
  }
}
</style>
