<template>
  <div class="settings" v-if="!isLoadingFolders">
    <div class="settings__container">
      <div class="settings__menu settings__menu_top">
        <div class="settings__menu_flex">
          <ButtonCard
            v-for="(button, index) in listMenu"
            :key="index"
            :name="button.name"
            :icon="button.icon"
            @click="button.click"
          />
        </div>
        <div
          class="settings__list-run-projects"
          v-for="project in runProjects"
          :key="project.ppt_id"
        >
          <ButtonCard
            name="Is running now"
            icon="play"
            @click="startProject(project)"
            type="secondary"
            :iconLabel="project.name"
          />
          <ButtonCard
            name="Shutdown"
            icon="close"
            @click="stopProject(project)"
          />
        </div>
      </div>

      <div
        class="settings__menu settings__menu_flex settings__menu_bottom"
        v-if="user.admin"
      >
        <ButtonCard name="Help" icon="help" @chooseTab="chooseTab" />
        <ButtonCard
          name="Administration"
          icon="setting"
          @click="$router.push('/administration')"
        />
      </div>
    </div>
    <div>
      <router-view />
    </div>
    <!-- <NewMixForm key="openForm" v-if="activeTab === 'Open'" :type="activeTab" /> -->
    <ModalMix :isOpen="isShowFoderModal" @close="isShowFoderModal = false">
      <FoldersModal @selectProject="selectProject" type="open" />
    </ModalMix>
  </div>
</template>

<script>
import { computed, ref } from 'vue';
import ButtonCard from '@/components/cards/ButtonCard.vue';
// import NewMixForm from '@/components/forms/NewMixForm.vue';
import ModalMix from '@/components/modals/ModalMix.vue';
import FoldersModal from '@/components/modals/FoldersModal.vue';
import { getCurrentInstance } from 'vue';
import axios from 'axios';
import { useRoute } from 'vue-router/composables';

export default {
  name: 'SettingsPage',
  components: { ButtonCard, ModalMix, FoldersModal },
  setup() {
    const {
      proxy: { $store: store, $router: router },
    } = getCurrentInstance();

    const route = useRoute();

    const listMenu = ref([
      {
        name: 'New',
        icon: 'plus',
        click: () => {
          store.dispatch('folder/SELECT_ACTIVE_PROJECT', null);
          activeTab.value = '';
          if (route.path !== '/new') {
            router.push({ name: 'new' });
          }
        },
      },
      {
        name: 'Open',
        icon: 'open',
        click: () => {
          isShowFoderModal.value = true;
          if (route.path !== '/cloudmix') {
            router.push({ name: 'cloudmix' });
          }
        },
      },
    ]);

    const isShowFoderModal = ref(false);

    const activeTab = ref('');
    const chooseTab = (tab) => {
      activeTab.value = tab;
    };

    const isLoadingFolders = ref(false);

    const getFolders = async () => {
      isLoadingFolders.value = true;
      await store.dispatch('folder/GET_FOLDERS');
      isLoadingFolders.value = false;
    };

    getFolders();

    const selectProject = async ({ ppt_id, run_now }) => {
      const { data } = await axios.get(`api/project/${ppt_id}`);
      await store.dispatch('folder/SELECT_ACTIVE_PROJECT', {
        ppt_id,
        run_now,
        ...data,
      });
      isShowFoderModal.value = false;
      activeTab.value = 'Open';
      router.push({ path: '/cloudmix/open', query: { id: ppt_id } });
    };

    const stopProject = async (project) => {
      store.dispatch('folder/STOP_PROJECT', project);
    };

    const runProjects = computed(() => {
      const projects = store.state.folder.originFolders.filter(
        (item) => !!item.run_now
      );
      return projects.map((item) => {
        return {
          name: item?.ppt_desc.split('/').pop(),
          ...item,
        };
      });
    });

    const startProject = async (project) => {
      try {
        const {
          data: { module_id },
        } = await axios.get(`/api/projectInfo/${project.ppt_id}`);

        router.push({
          name: 'MixerPage',
          query: { id: module_id },
        });
      } catch (error) {
        //eslint-disable-next-line
        console.log(error);
      }
    };

    const user = computed(() => {
      return store.state.auth.user;
    });

    return {
      listMenu,
      chooseTab,
      activeTab,
      selectProject,
      stopProject,
      runProjects,
      startProject,
      user,
      isShowFoderModal,
      isLoadingFolders,
      route,
    };
  },
};
</script>

<style lang="scss" scoped>
.settings {
  display: flex;
  align-items: stretch;
  height: 100%;

  &__list-run-projects {
    display: flex;
    column-gap: 20px;
    row-gap: 18px;
    margin-top: 18px;
  }

  &__container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  &__menu {
    max-width: 540px;
    margin-right: 68px;

    &_flex {
      display: flex;
      flex-wrap: wrap;
      column-gap: 20px;
      row-gap: 18px;
    }

    &_top {
      margin-bottom: 124px;
    }
  }
}
</style>
