<template>
  <div class="user" @keyup.enter="validateForm">
    <div class="user__info">
      <div class="user__title">User info</div>
      <div class="user__form-search">
        <MtsFormGroup class="user__form-group">
          <DropdownMix
            v-model="email"
            :listEmails="storageEmails"
            ref="formGroup"
            @select="validateForm"
          />

          <div v-if="isUserExists === false" class="user__input-label_error">
            Пользователь не найден
          </div>
        </MtsFormGroup>

        <MixButton
          @click="validateForm"
          class="user__button-search"
          type="primary"
          size="medium"
        >
          OK
        </MixButton>
      </div>
      <UserInfo v-if="currentUser" :userInfo="currentUser" />
    </div>

    <div class="user__settings" v-if="user">
      <div class="user__title">User settings</div>
      <UserSettingTable :tableData="userSettings"></UserSettingTable>
    </div>
    <div class="user__project-list" v-if="userProjects.length">
      <div class="user__title">Project list</div>
      <ProjectListTable :tableData="userProjects"></ProjectListTable>
    </div>
  </div>
</template>

<script>
import { MtsFormGroup } from '@mts-ds/components-vue2';
import MixButton from '@core/src/components/common/buttons/MixButton.vue';
import { computed, ref, getCurrentInstance, onMounted, onUnmounted } from 'vue';
import Joi from 'joi';
import UserInfo from '@/components/cards/UserInfo.vue';
import UserSettingTable from '@/components/tables/UserSettingTable.vue';
import ProjectListTable from '@/components/tables/ProjectListTable.vue';
import DropdownMix from '@core/src/components/form/DropdownMix.vue';
import { sortBy } from 'lodash';
export default {
  components: {
    // MtsInput,
    MixButton,
    MtsFormGroup,
    UserInfo,
    UserSettingTable,
    ProjectListTable,
    DropdownMix,
  },
  name: 'UserPage',
  setup() {
    const {
      proxy,
      proxy: { $store: store },
    } = getCurrentInstance();

    const route = proxy.$route;
    const router = proxy.$router;

    const email = ref('');
    const paramsRoute = ref(null);

    onMounted(async () => {
      paramsRoute.value = route.query;
      if (paramsRoute.value.id) {
        await checkUser();
        email.value = currentUser.value.user_email;
      }
      // if (email.value) {
      //   validateForm();
      // }
    });

    const schema = computed(() => {
      return Joi.object().keys({
        email: Joi.string().email({
          minDomainSegments: 2,
          tlds: { allow: ['com', 'net', 'ru'] },
        }),
      });
    });

    const isValidEmail = ref(null);
    const formGroup = ref();

    const validateForm = () => {
      const { error } = schema.value.validate({ email: email.value });
      if (error) isValidEmail.value = false;
      else isValidEmail.value = true;
      formGroup.value.changeFocus(false);

      checkUser();
    };

    const currentUser = computed(() => {
      return store.state.admin.user || false;
    });

    const isUserExists = ref(true);

    const checkUser = async () => {
      const body = {
        email,
        id:
          !isValidEmail.value && !paramsRoute.value.id
            ? email.value
            : paramsRoute.value.id,
      };

      if (!isValidEmail.value) {
        delete body['email'];
      }
      const data = await store.dispatch('admin/GET_USER', body);
      if (!data) isUserExists.value = false;
      if (data) {
        email.value = data.user_email;
        setUserLocalStorage();
        if (paramsRoute.value.id !== data.user_id) {
          router.replace({ query: { id: data.user_id } });
        }
        paramsRoute.value.id = data.user_id;
        isUserExists.value = true;
        getUserSettings(data.user_id);
        getUserProjects(data.user_id);
      }
    };

    const storageEmails = computed(() => {
      return JSON.parse(localStorage.getItem('emailsList')) || [];
    });

    const setUserLocalStorage = () => {
      const isDublicateEmail = () => {
        const index = storageEmails.value.findIndex(
          (item) => item === email.value
        );
        return index >= 0;
      };
      if (isDublicateEmail() || email.value === '') return;
      let emails = storageEmails.value;
      if (emails.length > 9) {
        emails.shift();
        emails.push(email.value);
      } else {
        emails.push(email.value);
      }
      localStorage.setItem('emailsList', JSON.stringify(emails));
    };

    const userSettings = computed(() => {
      return sortBy(store.state.admin.userSettings, ['key', 'ppt_desc']);
    });

    const userProjects = computed(() => {
      return store.state.admin.userProjects;
    });

    const user = computed(() => {
      return store.state.admin.user;
    });

    const getUserSettings = async (user_id) => {
      await store.dispatch('admin/GET_USER_SETTINGS', user_id);
    };

    const getUserProjects = async (user_id) => {
      await store.dispatch('admin/GET_USER_PROJECTS', user_id);
    };

    onUnmounted(() => {
      store.dispatch('admin/CLEAR_DATA');
    });

    return {
      storageEmails,
      schema,
      email,
      validateForm,
      isValidEmail,
      checkUser,
      isUserExists,
      // user,
      userSettings,
      userProjects,
      currentUser,
      paramsRoute,
      user,
      formGroup,
    };
  },
};
</script>

<style lang="scss" scoped>
.user {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  column-gap: 32px;
  row-gap: 60px;

  &__info {
    grid-column: 1/5;
    margin-bottom: 76px;
  }

  &__settings {
    grid-column: 5/12;
  }

  &__project-list {
    grid-column: 1/10;
  }

  &__title {
    font-weight: 900;
    font-size: 24px;
    line-height: 28px;
    margin-bottom: 8px;
  }

  &__form-group {
    width: 100%;
    margin-right: 4px;
    margin-bottom: 28px;
  }

  &__input-label_error {
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: var(--color-text-negative);
  }

  &__form-search {
    display: flex;
    width: 100%;
  }

  &__button-search {
    width: 100px;
  }
}
</style>
