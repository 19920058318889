// import { sort } from 'core-js/core/array';

const getFistSessions = (arr) => {
  return arr.reduce((acc, current) => {
    if (current.ppt_desc.split('/')[0] === '' && current.ppt_id) {
      acc.push({ ...current, name: current.ppt_desc.split('/').pop() });
      return acc;
    }
    return acc;
  }, []);
};

export const getMapDesc = (arr) => {
  return arr.reduce(
    (accumulator, current) => {
      const nodes = current.ppt_desc.split('/');
      nodes.pop();

      const getNode = (node, depth = 0) => {
        const isLastDepth = nodes.length - 1 === depth;
        const currentNodeName = nodes.slice(depth)[0];
        const currentNode = () =>
          node?.children?.find((item) => item.name === currentNodeName);

        if (!currentNodeName) return;

        if (!Object.prototype.hasOwnProperty.call(node, 'children')) {
          node.children = [];
        }

        if (!currentNode()) {
          node?.children.push({
            ...current,
            ppt_desc: current.ppt_desc
              .split('/')
              .splice(0, depth + 1)
              .join('/'),
            name: nodes[depth],
            isNewFolder: false,
            children: [],
            sessions: [],
          });
        }
        if (currentNode() && isLastDepth && current.ppt_id) {
          currentNode().sessions.push({
            ...current,
            name: current.ppt_desc.split('/').pop(),
          });
        }
        return getNode(currentNode(), (depth += 1));
      };

      getNode(accumulator);
      return accumulator;
    },
    { children: [], sessions: getFistSessions(arr), ppt_desc: '' }
  );
};

export const findByDesc = (folders, desc) => {
  return desc.reduce((accumulator, current) => {
    return accumulator?.children?.find((item) => item.name === current);
  }, folders);
};

export const sortDesc = (data, field = 'ppt_desc') => {
  const compare = (a, b) => {
    if (a[field] > b[field]) {
      return 1;
    }
    if (a[field] < b[field]) {
      return -1;
    }
    return 0;
  };
  return data?.sort(compare);
};
