<template>
  <table class="table">
    <thead class="table__thead">
      <tr>
        <th v-for="(item, index) in tableData.head" :key="index">
          {{ item }}
        </th>
        <th :width="`${widthControls}px`" v-if="slotsList['head-controls']">
          <slot name="head-controls" />
        </th>
      </tr>
    </thead>
    <tbody
      :style="`max-height: ${bodyHeight}px`"
      ref="tbody"
      class="table__tbody"
    >
      <tr v-for="(row, index) in tableData.rows" :key="row.ppt_id">
        <td
          :style="`height: ${rowHeight}px`"
          :height="`${rowHeight}px`"
          v-for="(col, indexCol) in tableData.head"
          :key="`${indexCol}${col}`"
        >
          <slot :name="`col-${col}`">
            <span>{{ row[col] }}</span>
          </slot>
        </td>
        <td :width="`${widthControls}px`" v-if="slotsList['row-controls']">
          <slot v-bind="{ ...row, index }" name="row-controls" />
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
import PerfectScrollbar from 'perfect-scrollbar';
import 'perfect-scrollbar/css/perfect-scrollbar.css';
import { computed, onMounted, ref, toRefs, watch } from 'vue';

export default {
  components: {},
  props: {
    tableData: {
      default: () => ({}),
      type: Object,
    },
    widthControls: {
      default: 80,
      type: Number,
    },
    rowHeight: {
      default: 28,
      type: Number,
    },
    bodyHeight: {
      default: 304,
      type: Number,
    },
    disabledScroll: {
      default: false,
      type: Boolean,
    },
  },
  setup(props, { slots }) {
    const tbody = ref();
    const { disabledScroll } = toRefs(props);

    watch(disabledScroll, () => {
      if (disabledScroll.value) {
        ps.value.destroy();
        ps.value = null;
        return;
      }
      ps.value = new PerfectScrollbar(tbody.value, { wheelPropagation: false });
    });

    const ps = ref(null);
    onMounted(() => {
      ps.value = new PerfectScrollbar(tbody.value, { wheelPropagation: false });
    });

    const slotsList = computed(() => {
      return slots;
    });

    return {
      tbody,
      slotsList,
    };
  },
};
</script>

<style lang="scss" scoped>
.table {
  width: 100%;
  border-spacing: 0px;
  table-layout: fixed;
  overflow: hidden;
  border-radius: 8px;

  tr {
    width: 100%;
    display: table;
  }

  &__tbody {
    display: block;
    position: relative;
    overflow-y: auto;
    overflow-x: hidden;
    font-family: 'MTS Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    padding: 0px 8px;
    background-color: var(--color-constant-greyscale-800);

    tr {
      display: table;
      width: 100%;
      table-layout: fixed;

      &:hover {
        background-color: var(--color-constant-greyscale-700);
      }
    }

    td {
      height: 28px;
      padding: 0 20px;
      overflow: hidden;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

      &:not(:last-child) {
        border-right: 2px solid var(--color-background-primary);
      }
    }
  }

  &__content {
    width: 100%;
    border-spacing: 0;
    table-layout: fixed;
  }

  &__thead {
    background-color: var(--color-constant-greyscale-700);
    margin-bottom: 2px;
    display: table;
    width: 100%;
    table-layout: fixed;
    tr {
      th {
        text-align: center;
        height: 40px;
      }
    }
  }
}
</style>
