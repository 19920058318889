var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"user-setting-table"},[_c('TableMix',{staticClass:"user-setting-table__table",attrs:{"tableData":_vm.tableUserSettings,"widthControls":102,"rowHeight":40,"bodyHeight":262},scopedSlots:_vm._u([{key:"head-controls",fn:function(){return undefined},proxy:true},{key:"row-controls",fn:function({
        setting,
        value,
        projectId,
        index,
        project,
        projectObj,
      }){return [_c('UserSettingControls',{attrs:{"params":{ setting, value, projectId, index, project, projectObj }}})]}}])}),_c('div',{staticClass:"user-setting-table__footer"},[_c('MixButton',{on:{"click":function($event){_vm.isShowEditModal = true}}},[_c('IconMix',{staticClass:"button__icon",attrs:{"icon":"button-plus"}}),_c('span',[_vm._v("Add settings")])],1)],1),_c('ModalMix',{attrs:{"size":"small","isOpen":_vm.isShowEditModal},on:{"close":function($event){_vm.isShowEditModal = false}}},[_c('UserSettingEditForm',{attrs:{"type":"add","info":_vm.params},on:{"close":function($event){_vm.isShowEditModal = false}}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }