<template>
  <div class="settings">
    <NewMixForm :key="'newSettingform'" type="open" />
  </div>
</template>

<script>
import NewMixForm from '@/components/forms/NewMixForm.vue';

export default {
  name: 'OpenSettingsPage',
  components: {
    NewMixForm,
  },
  setup() {
    return {};
  },
};
</script>
