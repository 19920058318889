<template>
  <div class="common-layout">
    <div v-click-outside="outsideClick">
      <div class="common-layout__header">
        <div @click="showHideSidebar" class="common-layout__burger">
          <IconMix icon="burger" />
        </div>
        <RouterLink to="/" class="common-layout__logo-link">
          <Logo class="common-layout__logo" />
        </RouterLink>
      </div>
      <div
        :class="[
          'common-layout__sidebar',
          { 'common-layout__sidebar_hidden': !isShowSidebar },
        ]"
      >
        <Transition name="slide-fade">
          <Sidebar v-if="isShowSidebar" @showHideSidebar="showHideSidebar" />
        </Transition>
      </div>
    </div>
    <div class="common-layout__content">
      <div class="common-layout__title">{{ route.meta.title }}</div>
      <slot />
    </div>
  </div>
</template>

<script>
import Sidebar from '@/components/layouts/Sidebar.vue';
import { computed, onBeforeMount, onMounted, onUnmounted, ref } from 'vue';
import { useRoute } from 'vue-router/composables';
import Logo from '@/assets/icons/logo.svg?inline';
import IconMix from '@core/src/components/common/icon/IconMix.vue';
import ClickOutside from 'vue-click-outside';

export default {
  name: 'CommonLayout',
  directives: {
    ClickOutside,
  },
  components: {
    Sidebar,
    Logo,
    IconMix,
  },
  setup() {
    const route = useRoute();
    const isShowSidebar = ref(true);

    const showHideSidebar = () => {
      isShowSidebar.value = !isShowSidebar.value;
    };

    const windowWidth = computed(() => {
      return window.innerWidth;
    });

    const listenerResise = () => {
      if (window.innerWidth >= 1440) isShowSidebar.value = true;
      else isShowSidebar.value = false;
    };

    onMounted(() => {
      addEventListener('resize', listenerResise);
    });

    onUnmounted(() => {
      removeEventListener('resize', listenerResise);
    });

    onBeforeMount(() => {
      if (window.innerWidth <= 1440) isShowSidebar.value = false;
    });

    const outsideClick = () => {
      if (window.innerWidth >= 1440) return;
      isShowSidebar.value = false;
    };

    return {
      route,
      isShowSidebar,
      showHideSidebar,
      windowWidth,
      outsideClick,
    };
  },
};
</script>

<style lang="scss">
.common-layout {
  width: 100%;

  &__content {
    padding: 32px;
    width: 100%;
    padding-left: 0;
    min-height: 100vh;
    padding: 76px 20px 20px;

    @include at('middle') {
      padding: 80px 40px 20px;
    }

    @include at('large') {
      padding: 80px 32px 20px;
    }

    @include at('xlarge') {
      padding: 32px 20px 20px 288px;
    }
  }

  &__title {
    font-weight: 400;
    font-size: 20px;
    line-height: 28px;
    color: #fff;
    margin-bottom: 28px;
    font-family: 'MTS Compact';
  }

  &__sidebar {
    position: fixed;
    height: 100vh;
    top: 0;
    z-index: 100;
    width: 256px;
    min-width: 256px;

    &_hidden {
      pointer-events: none;
    }
  }

  &__header {
    position: fixed;
    top: 0;
    display: flex;
    align-items: center;
    z-index: 1;
    // position: relative;
    padding: 20px 32px 20px;
    line-height: 0;
    z-index: 999;
  }

  &__burger {
    margin-right: 35px;
    cursor: pointer;

    @include at('xlarge') {
      display: none;
    }
  }

  &__logo {
    margin-right: 6px;
  }

  .slide-fade-enter-active {
    transition: all 0.4s ease-out;
    transform: translateX(-256px);
  }

  .slide-fade-leave-active {
    transition: all 0.4s cubic-bezier(1, 0.5, 0.8, 1);
  }

  .slide-fade-enter-from,
  .slide-fade-leave-to {
    transform: translateX(-256px);
    opacity: 0;
  }

  .slide-fade-enter-to {
    transform: translateX(0);
    opacity: 1;
  }
}
</style>
