var render = function render(){var _vm=this,_c=_vm._self._c;return _c('button',{class:[
    'button',
    'mts-p1-regular',
    `button_type-${_vm.type}`,
    `button_size-${_vm.size}`,
    `button_type-${_vm.type}_${_vm.state}`,
    `${_vm.styleText}`,
    { button_disabled: _vm.disabled },
  ],attrs:{"disabled":_vm.disabled},on:{"click":function($event){return _vm.$emit('click')}}},[(!_vm.isLoading)?[(_vm.type === 'icon')?_c('div',{key:"icon",staticClass:"button__icon"},[_vm._t("icon",function(){return [_c('IconMix',{attrs:{"icon":_vm.icon}})]})],2):_vm._t("default")]:_c('div',{key:"preloader",staticClass:"preloader"},[_c('IconMix',{staticClass:"preloader__icon",attrs:{"width":"20px","height":"20px","icon":"preloader","viewBox":"0 0 40 40"}})],1)],2)
}
var staticRenderFns = []

export { render, staticRenderFns }