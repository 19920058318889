import Vue from 'vue';

export default {
  SET_INPUT(state, input) {
    state.input = {
      format: input.format,
      ...input,
    };
  },
  SET_INPUT_STATUS(state, status) {
    Vue.set(state.input, 'status', status);
  },
  SET_OUTPUTS(state, outputs) {
    Vue.set(state, 'outputs', outputs);
  },
  CLEAR_INPUT(state) {
    state.input = null;
  },
  DELETE_OUTPUT(state, outputId) {
    const index = state.outputs.findIndex(
      (item) => item.output_id === outputId
    );
    state.outputs.splice(index, 1);
  },

  SET_PROJECT(state, project) {
    state.project = project;
  },
  SET_OUTPUT_STATUS(state, data) {
    if (data.error) {
      // если ошибка - гасим все выходы - они уже не online
      state.outputs.forEach((output) => {
        Vue.set(output, 'online', false);
      });
    } else {
      const map = new Map();
      // кешируем данные, что бы не было постоянного поиска по массиву
      data.outputs.forEach((item) => map.set(item.iid, item));
      // идти надо по тому что нарисованно, а не по тому, что пришло
      state.outputs.forEach((output) => {
        const current = map.get(output.iid);
        if (current === undefined) Vue.set(output, 'online', false);
        else Vue.set(output, 'online', current.online);
      });
    }
  },
  SET_IS_SAVE_INPUT(state, data) {
    state.isSavedInput = data;
  },
};
