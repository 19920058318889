import axios from 'axios';
import { list } from './listDictionaries';

export default {
  async GET_DICTIONARIES({ commit }) {
    list.forEach((item) => {
      axios.get(item.apiPath).then((response) => {
        commit('SET_DICTIONARIES', { data: response.data, name: item.name });
      });
    });
  },
};
