<template>
  <div class="folders-modal">
    <div class="folders-modal__title">Project path</div>
    <div class="line" />

    <div class="folders-modal__content">
      <div
        @click="upTheFolder"
        v-if="currentDesc.length"
        class="folders-modal__item folders-modal__back-folder"
      >
        <IconMix class="folders-modal__icon" icon="back" />
        <span class="mts-p3-regular">{{ backPath }}</span>
      </div>
      <div
        :class="[
          'folders-modal__item',
          { 'folders-modal__item_active': item.isActive },
        ]"
        v-for="(item, index) in listNodes"
        :key="index"
        @click="openFolder(item)"
      >
        <!-- @click="selectItem(item)" -->
        <IconMix class="folders-modal__icon" icon="folder" />
        <input
          v-if="item?.isNewFolder"
          @keyup.enter="inputFocusOut(item, 'enter')"
          @focusout="inputFocusOut(item)"
          class="folders-modal__input"
          v-model="item.name"
          ref="inputs"
          @keypress="onlyText"
        />
        <span v-else class="mts-p3-regular">{{ item.name }}</span>
      </div>

      <div
        @click="addNewFolder"
        class="folders-modal__item"
        v-if="!listNodes.length && type === 'create'"
      >
        add path
      </div>
      <template v-if="listSession.length">
        <div class="line" />
        <div
          :class="[
            'folders-modal__item',
            'folders-modal__item-session',
            {
              'folders-modal__item_active':
                item?.name === selectedProject?.name,
            },
            {
              'folders-modal__item_disabled': type === 'create',
            },
          ]"
          v-for="(item, index) in listSession"
          :key="`${item.name}${index}`"
          @click="selectProject(item)"
        >
          <div>
            <IconMix class="folders-modal__icon" icon="file" />
            <span class="mts-p3-regular">{{ item.name }}</span>
          </div>

          <IconMix
            v-if="type !== 'create'"
            icon="close-folder"
            @click.native.stop="deleteProject(item)"
            class="folders-modal__icon-folder-close"
          />
        </div>
      </template>
    </div>
    <div class="line" />
    <div class="folders-modal__footer">
      <MixButton
        v-if="type === 'create'"
        size="medium"
        class="folders-modal__button_new"
        type="secondary"
        @click="addNewFolder"
      >
        <template v-slot:icon>
          <IconMix
            viewBox="0 0 10 10"
            width="16"
            height="16"
            icon="button-plus"
          ></IconMix>
        </template>

        <span>New folder</span>
      </MixButton>
      <MixButton
        v-if="type === 'create'"
        @click="$emit('saveFolder', currentPath)"
        class="folders-modal__button_save"
        size="medium"
        :disabled="currentPath.ppt_desc === ''"
      >
        <span>Save</span>
      </MixButton>

      <MixButton
        v-if="type === 'open'"
        @click="$emit('selectProject', selectedProject)"
        class="folders-modal__button_save"
        size="medium"
        :disabled="selectedProject === null"
      >
        <span>Open</span>
      </MixButton>
    </div>
  </div>
</template>

<script>
import Vue, { computed, nextTick, onMounted, ref } from 'vue';
import IconMix from '@core/src/components/common/icon/IconMix.vue';
import MixButton from '@core/src/components/common/buttons/MixButton.vue';
import { getCurrentInstance } from 'vue';
import { sortDesc, findByDesc } from '@/helpers/folder';
import { sortBy } from 'lodash';
import ClickOutside from 'vue-click-outside';

export default {
  directives: {
    ClickOutside,
  },
  components: { IconMix, MixButton },
  name: 'FoldersModal',
  props: {
    type: {
      type: String,
      default: 'create',
    },
    descValue: {
      type: String,
      default: '',
    },
  },
  setup(props) {
    const {
      proxy: { $store: store },
    } = getCurrentInstance();

    const inputs = ref();
    const selectedProject = ref(null);

    const addNewFolder = async () => {
      if (props.type !== 'create') return;
      store.dispatch('folder/ADD_FOLDER', currentDesc.value);
      await nextTick();
      inputs?.value[0]?.focus();
    };

    onMounted(() => {
      selectedProject.value = null;
    });

    const listPath = computed(() => {
      return store.state.folder.folders;
    });

    const findDublicate = (newFolder) => {
      const folderDublicate = listNodes.value.find(
        (item) => item.name === newFolder.name && !item.isNewFolder
      );
      return !!folderDublicate;
    };

    const isCreating = ref(false);
    const inputFocusOut = (newFolder, typeEvent = 'focus') => {
      if (isCreating.value && typeEvent === 'focus') {
        isCreating.value = false;
        return;
      }
      if (typeEvent === 'enter') isCreating.value = true;
      if (findDublicate(newFolder)) {
        Vue.$toast('Дубликат названия папки', { timeout: 5000, type: 'error' });
      }
      store.dispatch('folder/CHANGE_DESC_FOLDER', {
        desc: [...currentDesc.value, newFolder.name],
        isDublicate: findDublicate(newFolder),
      });
      selectItem(newFolder);
    };

    const selectItem = (item) => {
      store.dispatch('folder/CHANGE_ACTIVE_FOLDER', item.ppt_desc.split('/'));
    };
    const selectedItem = computed(() => {
      return store.state.folder.activeFolder;
    });

    const currentDesc = ref(props.descValue ? props.descValue.split('/') : []);

    const openFolder = (item) => {
      window.getSelection().removeAllRanges();
      store.dispatch('folder/CHANGE_ACTIVE_FOLDER', null);
      const arrDesc = [...item.ppt_desc.split('/')];
      currentDesc.value = arrDesc;
    };

    const listNodes = computed(() => {
      if (!listPath.value) return [];
      return sortBy(findByDesc(listPath.value, currentDesc.value)?.children, [
        (obj) => {
          if (!obj.isNewFolder) return obj.name;
        },
      ]);
    });

    const listSession = computed(() => {
      return (
        sortDesc(
          findByDesc(listPath.value, currentDesc.value)?.sessions,
          'name'
        ) || []
      );
    });

    const onlyText = (event) => {
      if (!/^[a-zа-яё0-9]|\s+$/i.test(event.key)) return event.preventDefault();
    };

    const upTheFolder = () => {
      store.dispatch('folder/CHANGE_ACTIVE_FOLDER', null);
      currentDesc.value.pop();
      selectedProject.value = null;
    };

    const backPath = computed(() => {
      const path = [...currentDesc.value];
      return path.length > 1 ? path.pop() : path[0];
    });

    const selectProject = (item) => {
      if (props.type === 'create') return;
      selectedProject.value = item;
    };

    const deleteProject = (item) => {
      store.dispatch('folder/DELETE_PROJECT', item);
    };

    const currentPath = computed(() => {
      return findByDesc(listPath.value, currentDesc.value);
    });

    return {
      listPath,
      addNewFolder,
      inputFocusOut,
      inputs,
      selectItem,
      selectedItem,
      openFolder,
      currentDesc,
      listNodes,
      onlyText,
      listSession,
      upTheFolder,
      backPath,
      selectedProject,
      selectProject,
      deleteProject,
      currentPath,
    };
  },
};
</script>

<style lang="scss">
.folders-modal {
  &__title {
    font-family: 'MTS Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 28px;
    padding: 0 16px;
  }

  .line {
    background-color: var(--color-constant-greyscale-600);
    width: 100%;
    height: 1px;
    margin: 20px 0;
  }

  &__icon {
    margin-right: 9px;
    height: 14px;
  }

  &__content {
    min-height: 606px;
    max-width: 728px;
  }

  &__icon-folder-close {
    display: none;
    &:hover {
      * {
        fill: white;
      }
    }
  }

  &__item {
    display: flex;
    align-items: center;
    height: 30px;
    padding: 0 16px;

    &_active {
      background-color: var(--color-constant-greyscale-600);
    }

    &:hover {
      cursor: pointer;
      background-color: var(--color-constant-greyscale-600);

      .folders-modal__icon-folder-close {
        display: block;
      }
    }

    &_disabled {
      &:hover {
        cursor: auto;
        background-color: inherit;
      }
    }

    span {
      line-height: 16px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: calc(100% - 21px);
    }
  }

  &__footer {
    display: flex;
    justify-content: flex-end;
  }

  &__button {
    &_new {
      margin-right: 17px;
      width: 195px;
    }

    &_save {
      width: 160px;
    }
  }

  &__input {
    background-color: transparent;
    border: none;
    outline: none;
    color: var(--color-text-primary);
    font-size: 12px;
    line-height: 16px;
    padding-left: 0;
    width: 100%;
  }

  &__back-folder {
    margin-bottom: 12px;
  }

  &__item-session {
    display: flex;
    align-items: center;
    justify-content: space-between;

    & > div {
      width: calc(100% - 20px);
      display: flex;
      align-items: center;
    }
  }
}
</style>
