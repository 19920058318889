<template>
  <div class="sidebar">
    <div class="sidebar__list-menu">
      <RouterLink
        tag="div"
        class="sidebar__link"
        :to="link.path"
        v-for="(link, index) in links"
        :key="index"
        active-class="sidebar__link_active"
      >
        {{ link.name }}
      </RouterLink>
    </div>
  </div>
</template>

<script>
import { RouterLink } from 'vue-router';
import { ref } from 'vue';

export default {
  components: {
    RouterLink,
  },
  name: 'Sidebar',
  setup() {
    const links = ref([
      { path: '/cloudmix', name: 'Облачный видеопродакшн' },
      { path: '/restream', name: 'Рестрим студия' },
      // { path: '/editor', name: 'Клипы' },
      // { path: '/scheduler', name: 'Планировщик эфиров' },
      // { path: '/statistics', name: 'Статистика' },
    ]);
    return {
      links,
    };
  },
};
</script>

<style lang="scss" scoped>
.sidebar {
  background-color: #1d2023;
  padding: 68px 32px 28px;
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;

  &__link {
    color: var(--color-text-headline);
    text-decoration: none;
    padding: 12px 16px;
    margin: 0 -16px;
    font-size: 14px;
    display: block;
    border-radius: 6px;
    cursor: pointer;

    &_active {
      background-color: #2c3135;
    }
  }
}
</style>
