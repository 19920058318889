<template>
  <div
    :class="[
      'restream-output-card',
      {
        'restream-output-card_disabled': !isSavedInput && project,
      },
    ]"
  >
    <div class="restream-output-card__title">Выход</div>
    <div class="restream-output-card__list">
      <div
        class="restream-output-card__item"
        v-for="(item, index) in listOutputs"
        :key="item.output_id"
      >
        <div class="restream-output-card__item-content">
          <div
            :class="[
              'restream-output-card__item-status',
              {
                'restream-output-card__item-status_stream': item?.online,
              },
            ]"
          />
          <div class="restream-output-card__item-name">
            {{ item.description }}
          </div>
        </div>
        <div class="restream-output-card__item-controls">
          <IconMix
            class="button-icon restream-output-card__item-icon-delete"
            icon="button-delete"
            :disabled="!item.disabled"
            @click.native="deleteOutput(item.output_id)"
          />
          <IconMix
            class="button-icon restream-output-card__item-icon-settings"
            icon="settings"
            @click.native="changeOutput(item, index)"
          />
          <SwitcherMix
            :value="!item.disabled"
            @input="
              (value) => {
                startStopStream(value, index);
              }
            "
          />
        </div>
      </div>
    </div>

    <MixButton
      size="medium"
      type="secondary"
      class="restream-output-card__button-add"
      @click="isShowRestreamModal = true"
      :disabled="!store.state.restream.input"
    >
      <IconMix class="restream-output-card__button-icon" icon="button-plus" />
      <span>Добавить</span>
    </MixButton>

    <ModalMix
      title="Выход"
      size="small"
      :isOpen="isShowRestreamModal"
      @close="closeModal"
    >
      <RestreamOutputForm
        @close="closeModal"
        :currentChangingOutput="currentChangingOutput"
        :currentIndex="currentIndex"
      />
    </ModalMix>
    <div v-if="!store.state.restream.input" class="restream-output-card__hint">
      Для добавления выхода необходимо выбрать вход
    </div>
  </div>
</template>

<script>
import {
  computed,
  getCurrentInstance,
  nextTick,
  onBeforeUnmount,
  onMounted,
  ref,
} from 'vue';
import IconMix from '@core/src/components/common/icon/IconMix.vue';
import SwitcherMix from '@core/src/components/common/SwitcherMix.vue';
import MixButton from '@core/src/components/common/buttons/MixButton.vue';
import ModalMix from '@/components/modals/ModalMix.vue';
import RestreamOutputForm from '@/components/forms/RestreamOutputForm.vue';
// import axios from 'axios';

export default {
  name: 'RestreamOutputCard',
  components: {
    IconMix,
    SwitcherMix,
    MixButton,
    ModalMix,
    RestreamOutputForm,
  },
  props: {},
  setup() {
    const {
      proxy: { $store: store },
    } = getCurrentInstance();

    let currentChangingOutput = ref(null);
    let currentIndex = ref(null);
    const isShowRestreamModal = ref(false);
    const intervalCheckStatus = ref(null);

    onMounted(async () => {
      intervalCheckStatus.value = setInterval(() => {
        store.dispatch('restream/GET_STATUS_OUTPUT');
      }, 10000);
    });

    onBeforeUnmount(() => {
      clearInterval(intervalCheckStatus.value);
    });

    const listOutputs = computed(() => {
      return store.state.restream.outputs;
    });

    const isSavedInput = computed(() => {
      return store.state.restream.isSavedInput;
    });

    const project = computed(() => {
      return store.state.restream.project;
    });

    const startStopStream = (value, index) => {
      if (!isSavedInput.value && project.value) return;
      if (value) {
        store.dispatch('restream/START_OUTPUT_STREAM', index);
      } else {
        store.dispatch('restream/STOP_OUTPUT_STREAM', index);
      }
    };

    const changeOutput = async (output, index) => {
      if (!isSavedInput.value && project.value) return;
      currentIndex.value = index;
      currentChangingOutput.value = output;
      await nextTick();
      isShowRestreamModal.value = true;
    };

    const closeModal = () => {
      currentChangingOutput.value = null;
      isShowRestreamModal.value = false;
    };

    const deleteOutput = async (outputId) => {
      if (!isSavedInput.value && project.value) return;
      store.dispatch('restream/DELETE_OUTPUT', outputId);
    };

    return {
      listOutputs,
      startStopStream,
      isShowRestreamModal,
      store,
      changeOutput,
      currentChangingOutput,
      closeModal,
      deleteOutput,
      currentIndex,
      isSavedInput,
      project,
    };
  },
};
</script>

<style lang="scss" scoped>
.restream-output-card {
  border-radius: 12px;
  background-color: $color-background-primary-elevated;
  padding: 16px;
  position: relative;

  &_disabled {
    &:before {
      content: '';
      width: 100%;
      height: 100%;
      position: absolute;
      background-color: $color-background-primary-elevated;
      opacity: 0.6;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      border-radius: 12px;
      z-index: 99;
    }
  }

  &__title {
    margin-bottom: 28px;
    font-family: 'MTS Compact';
    font-style: normal;
    font-weight: 400;
    font-size: 17px;
    line-height: 24px;
  }

  &__item {
    display: flex;
    align-items: center;
    justify-content: space-between;

    &:not(:last-child) {
      margin-bottom: 20px;
    }

    &-status {
      margin-right: 8px;
      width: 8px;
      height: 8px;
      border-radius: 50%;
      background-color: $color-text-tertiary;

      &_error {
        background-color: $color-accent-negative;
      }

      &_stream {
        background-color: $color-accent-positive;
      }
    }

    &-content,
    &-controls {
      display: flex;
      align-items: center;
    }

    &-content {
      max-width: 64%;
    }

    &-name {
      font-family: 'MTS Compact';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      max-width: 90%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &-icon-delete {
      margin-right: 14px;
    }
    &-icon-settings {
      margin-right: 19px;
    }
  }

  &__button {
    &-add {
      margin-top: 24px;
      width: 100%;
    }
    &-icon {
      margin-right: 12px;
    }
  }

  &__hint {
    font-family: 'MTS Compact';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: $color-text-negative;
    margin-top: 3px;
  }
  :deep(.button-icon) {
    cursor: pointer;

    path {
      fill: $color-text-tertiary !important;
    }
  }
}
</style>
