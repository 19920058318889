<template>
  <ModalMix :isOpen="isShowModal" size="medium" :disableClose="true">
    <div class="interview-modal">
      <div class="interview-modal__title">
        Как вы оцениваете идею удалённого <br />
        продакшена?
      </div>
      <MtsRadioGroup v-model="form.rating" class="interview-modal__radio-group">
        <template v-for="item in ratingsList">
          <MtsFormGroup :key="item.value">
            <MtsLabel>{{ item.name }}</MtsLabel>
            <MtsRadio size="s" :value="item.value"></MtsRadio>
          </MtsFormGroup>
        </template>
      </MtsRadioGroup>
      <MtsTextarea
        class="interview-modal__comment"
        v-model="form.comment"
        placeholder="Ваш комментарий (по желанию)"
      />
    </div>

    <div class="interview-modal__controls">
      <MixButton
        size="medium"
        class="interview-modal__button"
        @click="sendForm"
        :disabled="form.rating === ''"
      >
        Отправить
      </MixButton>
    </div>
  </ModalMix>
</template>

<script>
import { onMounted, ref } from 'vue';
import ModalMix from './ModalMix.vue';
import {
  MtsRadioGroup,
  MtsFormGroup,
  MtsRadio,
  MtsLabel,
  MtsTextarea,
} from '@mts-ds/components-vue2';
import MixButton from '@core/src/components/common/buttons/MixButton.vue';
import axios from 'axios';
export default {
  components: {
    ModalMix,
    MtsRadioGroup,
    MtsFormGroup,
    MtsRadio,
    MtsLabel,
    MtsTextarea,
    MixButton,
  },
  setup() {
    const form = ref({
      rating: '',
      comment: '',
    });

    const ratingsList = ref([
      {
        name: 'Позитивно, буду пользоваться',
        value: 'Позитивно, буду пользоваться',
      },
      { name: 'Ещё не решил', value: 'Ещё не решил' },
      { name: 'Не буду пользоваться', value: 'Не буду пользоваться' },
    ]);
    const isShowModal = ref(false);

    const isSurveyCompleted = ref(false);

    onMounted(async () => {
      await checkQuiz();
      runTimerQuiz();
    });

    const checkQuiz = async () => {
      const { data } = await axios.get('/api/check-quiz/1');
      isSurveyCompleted.value = !!data;
    };

    const runTimerQuiz = () => {
      if (isSurveyCompleted.value) return;
      const minutes = 5;
      setTimeout(() => {
        isShowModal.value = true;
      }, 1000 * 60 * minutes);
    };

    const sendForm = async () => {
      await axios.post('/api/quiz/1', { body: form.value });
      isShowModal.value = false;
    };
    return {
      form,
      isShowModal,
      ratingsList,
      sendForm,
    };
  },
};
</script>

<style lang="scss">
.interview-modal {
  &__title {
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    margin-bottom: 32px;
  }

  &__radio-group {
    margin-bottom: 16px;
  }

  &__comment {
    height: 164px;
    margin-bottom: 32px;
  }

  &__controls {
    display: flex;
    justify-content: flex-end;
  }

  &__button {
    width: 192px;
  }
}
</style>
