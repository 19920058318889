<template>
  <div
    @click="$emit('click')"
    :class="`status-project status-project_${label}`"
  >
    {{ label }}
  </div>
</template>

<script>
import { computed, ref } from 'vue';

export default {
  props: {
    status: {
      type: Number,
      default: 0,
    },
  },
  setup(props) {
    const labels = ref({ 0: 'off', 1: 'online', 2: 'onlineError' });

    const label = computed(() => {
      return labels.value[props.status];
    });

    return {
      label,
    };
  },
};
</script>

<style lang="scss" scoped>
.status-project {
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 136px;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  border-radius: 6px;

  &_off {
    background-color: var(--color-constant-greyscale-800);
  }

  &_online {
    background-color: var(--color-control-primary-active);
  }

  &_onlineError {
    background-color: var(--color-constant-Banana);
  }

  &:hover {
    cursor: pointer;
  }
}
</style>
