<template>
  <div class="user-setting-edit-form">
    <div class="user-setting-edit-form__input-container" v-if="type === 'add'">
      <span class="user-setting-edit-form__label">setting</span>
      <div class="user-setting-edit-form__form-group">
        <MtsInput
          size="s"
          placeholder="setting"
          v-model="form.setting"
          type="text"
          class="user-setting-edit-form__input"
          @mtsBlur="onValidate"
          :valid="errorsList.setting ? false : undefined"
        />
        <div class="user-setting-edit-form__input-label_error">
          <span v-if="errorsList.setting">{{
            errorsList.setting.message
          }}</span>
        </div>
      </div>
    </div>

    <div class="user-setting-edit-form__input-container">
      <span class="user-setting-edit-form__label">value</span>
      <div class="user-setting-edit-form__form-group">
        <MtsInput
          size="s"
          placeholder="value"
          class="user-setting-edit-form__input"
          v-model="form.value"
          type="text"
          @mtsBlur="onValidate"
          :valid="errorsList.value ? false : undefined"
        />
        <div class="user-setting-edit-form__input-label_error">
          <span v-if="errorsList.value">{{ errorsList.value.message }}</span>
        </div>
      </div>
    </div>

    <div class="user-setting-edit-form__input-container">
      <span class="user-setting-edit-form__label">project</span>
      <div class="user-setting-edit-form__form-group">
        <MtsDropdown
          class="user-setting-edit-form__input"
          v-model="form.project"
          size="s"
          placeholder="project"
          @mtsSelect="onValidate"
          :valid="errorsList.project ? false : undefined"
        >
          <MtsDropdownOption
            v-for="(channel, index) in projects"
            :key="index"
            :value="channel.value"
          >
            {{ channel.name }}
          </MtsDropdownOption>
        </MtsDropdown>
        <div class="user-setting-edit-form__input-label_error">
          <span v-if="errorsList.project">{{
            errorsList.project.message
          }}</span>
        </div>
      </div>
    </div>

    <div class="user-setting-edit-form__controls">
      <MixButton
        size="medium"
        @click="$emit('close')"
        class="user-setting-edit-form__button_save"
        type="secondary"
      >
        Cancel
      </MixButton>
      <MixButton
        size="medium"
        @click="saveSetting"
        class="user-setting-edit-form__button_start"
        :disabled="isDisableButton"
      >
        Save
      </MixButton>
    </div>
  </div>
</template>

<script>
import {
  MtsInput,
  MtsDropdown,
  MtsDropdownOption,
} from '@mts-ds/components-vue2';
import { computed, getCurrentInstance, onMounted, ref } from 'vue';
import MixButton from '@core/src/components/common/buttons/MixButton.vue';
import Joi from 'joi';
import axios from 'axios';

export default {
  components: {
    MtsInput,
    MtsDropdown,
    MtsDropdownOption,
    MixButton,
  },
  props: {
    info: {
      default: () => ({}),
      type: Object,
    },
    type: {
      type: String,
      default: 'edit',
    },
  },
  setup(props, { emit }) {
    const {
      proxy: { $store: store },
    } = getCurrentInstance();

    const form = ref({
      setting: '',
      value: '',
      project: '',
    });

    const projects = computed(() => {
      const options = store.state.admin.userProjects.map((item) => ({
        name: item.ppt_desc,
        value: item.ppt_id,
      }));
      return [{ name: 'All', value: 'All' }, ...options];
    });

    onMounted(() => {
      form.value = {
        ...props.info,
        project: props.info?.projectObj?.ppt_id || 'All',
      };
    });

    const userId = computed(() => {
      return store.state.admin.user.user_id;
    });

    const saveSetting = async () => {
      isFirstSend.value = false;
      onValidate();
      if (isDisableButton.value) return;
      const paramsUrl = {
        userId: userId.value,
        key: props.type === 'add' ? form.value.setting : props.info.setting,
      };
      const url = `/api/usersetting/set/${paramsUrl.userId}/${paramsUrl.key}/${
        form.value.project === 'All' ? null : form.value.project
      }`;

      await axios.post(url, form.value.value, {
        headers: {
          'content-type': 'text/plain; charset=utf-8',
        },
      });
      await store.dispatch('admin/GET_USER_SETTINGS', userId.value);

      emit('close');
    };

    const schema = computed(() => {
      return Joi.object().keys({
        setting: Joi.string().required(),
        value: Joi.string().required(),
        project: Joi.string().required(),
      });
    });

    const errorsList = ref({});

    const isDisableButton = computed(() => {
      return !!Object.keys(errorsList.value).length;
    });

    const isFirstSend = ref(true);

    const onValidate = () => {
      if (isFirstSend.value) return;
      const options = {
        messages: {
          'string.empty': 'required fields to be filled',
        },
        abortEarly: false,
        stripUnknown: true,
      };
      const { error: { details = [] } = {} } = schema.value.validate(
        form.value,
        options
      );

      errorsList.value = details.reduce((acc, current) => {
        acc[current.context.key] = {
          message: current.message,
          type: current.type,
        };
        return acc;
      }, {});
    };

    return {
      projects,
      form,
      saveSetting,
      isDisableButton,
      errorsList,
      onValidate,
    };
  },
};
</script>

<style lang="scss" scoped>
.user-setting-edit-form {
  &__input-container {
    display: flex;
    // align-items: center;
    margin-bottom: 10px;
  }

  &__input {
    width: 100%;
  }

  &__label {
    width: 80px;
    padding: 3px;
  }

  &__input-label_error {
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: var(--color-text-negative);
    height: 12px;
  }

  &__form-group {
    // margin-bottom: 10px;
    width: 100%;
  }

  &__controls {
    display: flex;
    justify-content: flex-end;
  }

  &__button {
    &_save {
      margin-right: 14px;
      width: 146px;
    }
    &_start {
      width: 146px;
    }
  }
}
</style>
