import Vue from 'vue';
import { findByDesc, getMapDesc } from '@/helpers/folder';

export default {
  SET_FOLDER(state, folders) {
    state.folders = folders;
  },

  SET_ORIGIN_FOLDER(state, folders) {
    state.originFolders = folders;
  },

  ADD_FOLDER(state, desc) {
    const node = findByDesc(state.folders, desc);
    node.children.push({
      name: '',
      isNewFolder: true,
      ppt_desc: `${desc.join('/') || ''}`,
      children: [],
    });
  },

  SET_DESC_FOLDER(state, { desc, isDublicate }) {
    const node = findByDesc(state.folders, desc);
    const { ppt_desc, name } = node;

    if (name === '' || isDublicate) {
      const parentDesc = desc;
      parentDesc.pop();
      const parentNode = findByDesc(state.folders, parentDesc);
      parentNode.children.pop();
    } else {
      node.ppt_desc = `${ppt_desc || ''}${ppt_desc ? '/' : ''}${name}`;
      node.isNewFolder = false;
    }
  },

  SET_ACTIVE_FOLDER(state, desc) {
    if (state.activeFolder) {
      Vue.set(state.activeFolder, 'isActive', false);
    }
    if (!desc) {
      state.activeFolder = null;
      return;
    }
    const node = findByDesc(state.folders, desc);

    Vue.set(node, 'isActive', true);
    state.activeFolder = node;
  },

  ADD_SESSION(state, session) {
    const node = findByDesc(state.folders, session.ppt_desc.split('/'));

    if (Object.prototype.hasOwnProperty.call(node, 'sessions')) {
      node.sessions.push({ ...session, name: session.ppt_config.mixer.name });
    } else {
      node.sessions = [{ ...session, name: session.ppt_config.mixer.name }];
    }
  },

  SELECT_ACTIVE_PROJECT(state, project) {
    if (!project) state.activeProject = null;
    else {
      state.activeProject = {
        ...project,
        ppt_config: {
          source: [],
          destinations: [],
          ...project.ppt_config,
          mixer: {
            ...project.ppt_config.mixer,
            name: project.ppt_desc.split('/').pop(),
          },
        },
        ppt_desc: project.ppt_desc.split('/').slice(0, -1).join('/'),
      };
    }
  },

  DELETE_PROJECT(state, project) {
    const index = state.originFolders.findIndex(
      (item) => item.ppt_id === project.ppt_id
    );
    state.originFolders.splice(index, 1);
    state.folders = getMapDesc(state.originFolders);
    // const node = findByDesc(state.folders, project.ppt_desc.split('/'));
  },

  STOP_PROJECT(state, project) {
    const runProject = state.originFolders.find(
      (item) => item.ppt_id === project.ppt_id
    );
    runProject.run_now = 0;
    const folders = getMapDesc(state.originFolders);
    state.folders = folders;
  },
};
