<template>
  <div>
    <div class="user-setting-controls__controls">
      <MixButton
        class="user-setting-controls__button"
        type="icon"
        @click="showEditProject"
      >
        <template #icon>
          <IconMix
            icon="button-edit"
            width="16px"
            height="16px"
            viewBox="0 0 20 20"
          />
        </template>
      </MixButton>
      <MixButton type="icon" icon="button-delete" @click="deleteSetting">
        <template #icon>
          <IconMix
            icon="button-delete"
            width="16px"
            height="16px"
            viewBox="0 0 20 20"
          />
        </template>
      </MixButton>
    </div>
    <ModalMix
      size="small"
      :isOpen="isShowEditModal"
      @close="isShowEditModal = false"
    >
      <UserSettingEditForm @close="isShowEditModal = false" :info="params" />
    </ModalMix>
  </div>
</template>

<script>
import { getCurrentInstance, ref } from 'vue';
import UserSettingEditForm from '@/components/forms/UserSettingEditForm.vue';
import ModalMix from '@/components/modals/ModalMix.vue';
import MixButton from '@core/src/components/common/buttons/MixButton.vue';
import IconMix from '@core/src/components/common/icon/IconMix.vue';

export default {
  components: {
    UserSettingEditForm,
    ModalMix,
    MixButton,
    IconMix,
  },
  props: {
    params: {
      type: Object,
      default: () => ({}),
    },
  },
  setup(props) {
    const { proxy } = getCurrentInstance();
    const store = proxy.$store;

    const isShowEditModal = ref(false);
    const showEditProject = () => {
      isShowEditModal.value = true;
    };

    const deleteSetting = async () => {
      await store.dispatch('admin/DELETE_SETTINGS', {
        ...props.params,
        user_id: store.state.admin.user.user_id,
      });
      await store.dispatch(
        'admin/GET_USER_SETTINGS',
        store.state.admin.user.user_id
      );
    };

    return {
      isShowEditModal,
      showEditProject,
      deleteSetting,
    };
  },
};
</script>

<style lang="scss" scoped>
.user-setting-controls {
  &__controls {
    display: flex;
  }

  &__button {
    margin-right: 8px;
  }
}
</style>
