<template>
  <div v-if="isOpen" class="modal-mix">
    <div class="modal-mix__container" ref="modalContainer">
      <div class="modal-mix__header">
        <div class="modal-mix__header-tittle">{{ title }}</div>
        <div class="close" @click="$emit('close')" v-if="!disableClose">
          <IconMix icon="modal-close" />
        </div>
      </div>
      <div
        :class="`modal-mix__content modal-mix__content_size--${size}`"
        ref="modalContent"
      >
        <slot />
      </div>
    </div>
  </div>
</template>

<script>
import IconMix from '@core/src/components/common/icon/IconMix.vue';
import PerfectScrollbar from 'perfect-scrollbar';
import 'perfect-scrollbar/css/perfect-scrollbar.css';
import { nextTick, ref, toRefs, watch } from 'vue';

export default {
  components: { IconMix },
  props: {
    isOpen: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String,
      default: 'normal',
    },
    disableClose: {
      type: Boolean,
      default: false,
    },
    isScrollModal: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: '',
    },
  },
  setup(props) {
    const ps = ref(null);
    const modalContent = ref(null);
    const modalContainer = ref(null);

    const { isOpen } = toRefs(props);

    watch(isOpen, async () => {
      if (isOpen.value) {
        await nextTick();
        await nextTick();
        setTimeout(() => {
          const heightContent = modalContainer.value.clientHeight;
          if (heightContent >= window.innerHeight - 100) {
            ps.value = new PerfectScrollbar(modalContent.value);
          }
        }, 1);
        document.querySelector('html').style.overflow = 'hidden';
        return;
      }
      document.querySelector('html').style.overflow = 'visible';
      ps.value = null;
    });

    return {
      modalContent,
      modalContainer,
    };
  },
};
</script>

<style lang="scss">
.modal-mix {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 999;

  &::before {
    content: '';
    width: 100%;
    height: 100%;
    background-color: #000;
    display: block;
    opacity: 0.79;
    z-index: 0;
    position: absolute;
  }

  &__header {
    position: absolute;
    width: 100%;
    background-color: var(--color-background-modal);
    height: 60px;
    z-index: 2;
    padding: 16px 20px;
    border-radius: 16px 16px 0 0;

    @include at('middle') {
      background-color: transparent;
    }

    &-tittle {
      font-family: 'MTS Compact';
      font-style: normal;
      font-weight: 500;
      font-size: 20px;
      line-height: 24px;

      @include at('middle') {
        display: none;
      }
    }
  }

  &__content {
    position: relative;
    z-index: 1;
    padding: 36px 24px 26px 24px;
    max-height: calc(100% - 60px);
    margin-top: 60px;

    @include at('middle') {
      margin-top: 0;
      max-height: calc(100vh - 100px);
      height: 100%;
      border-radius: 16px;
      padding: 26px 24px;
    }

    &_size--small {
      min-width: 320px;
      width: 100%;

      @include at('middle') {
        min-width: 432px;
        width: auto;
      }
    }

    &_size--medium {
      min-width: 544px;
    }

    &_size--normal {
      min-width: 776px;
    }

    &_size--large {
      min-width: 1216px;
    }
  }

  &__container {
    position: absolute;
    background-color: var(--color-background-modal);
    width: 100%;
    left: 50%;
    transform: translateX(-50%);
    border-radius: 16px 16px 0 0;
    bottom: 0;
    max-height: calc(100vh - 100px);
    height: calc(100vh - 100px);

    @include at('middle') {
      height: auto;
      width: auto;
      bottom: auto;
      top: 50%;
      transform: translate(-50%, -50%);
      border-radius: 16px;
    }
  }

  .close {
    position: absolute;
    cursor: pointer;
    right: 16px;
    top: 14px;
    z-index: 5;
    background-color: $color-control-tertiary-active;
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 6px;

    @include at('middle') {
      background-color: transparent;
      right: -32px;
      top: 5px;
    }
  }
}
</style>
