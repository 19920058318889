<template>
  <div class="restream-output-form">
    <InputMix
      class="restream-output-form__form-field"
      v-model="form.nameStream"
      label="Название трансляции"
      size="xs"
      :error="errorsList?.nameStream?.message"
      @input="validateForm"
    />
    <SelectMix
      class="restream-output-form__form-field"
      v-model="form.format"
      :options="optionsFormat"
      label="Тип выхода"
      :error="errorsList.format?.message"
      labelSelect="name"
      size="xs"
      placeholder="RTMP / SRT"
      @select="validateForm"
    />
    <!-- :disabled="!!currentOutputId && !store.state.input" -->
    <template v-if="form.format?.value === 'srt'">
      <div class="restream-output-form__input">
        <InputMix
          class="restream-output-form__form-field"
          v-model="form.hostName"
          label="Host name"
          size="xs"
          :error="errorsList.hostName?.message"
          @input="inputPort"
        />
        <CopyButton :value="form.hostName" />
      </div>

      <div class="restream-output-form__flex-container">
        <InputMix
          class="restream-output-form__form-field"
          v-model="form.port"
          label="Port"
          size="xs"
          :error="errorsList.port?.message"
          @input="validateForm"
        />
        <InputMix
          class="restream-output-form__form-field"
          v-model="form.latency"
          label="Latency"
          size="xs"
          :error="errorsList.latency?.message"
          @input="validateForm"
          type="number"
        />
      </div>
      <div class="restream-output-form__input">
        <InputMix
          class="restream-output-form__form-field"
          v-model="form.stream_id"
          label="Stream ID"
          size="xs"
          :hidden="true"
          :error="errorsList.stream_id?.message"
          @input="validateForm"
        />
        <CopyButton :value="form.stream_id" />
      </div>
      <div class="restream-output-form__input">
        <InputMix
          class="restream-output-form__form-field"
          v-model="form.password"
          label="Passphrase"
          :hidden="true"
          size="xs"
          :error="errorsList.password?.message"
          @input="validateForm"
        />
        <CopyButton :value="form.password" />
      </div>
    </template>
    <template v-else>
      <div class="restream-output-form__input">
        <InputMix
          class="restream-output-form__form-field"
          v-model="form.url"
          label="URL сервера"
          size="xs"
          :error="errorsList.url?.message"
          @input="inputUrl"
        />
        <CopyButton :value="form.url" />
      </div>
      <div class="restream-output-form__input">
        <InputMix
          class="restream-output-form__form-field"
          v-model="form.key"
          label="Ключ трансляции"
          :hidden="true"
          size="xs"
          :error="errorsList.key?.message"
          @input="validateForm"
        />
        <CopyButton :value="form.key" />
      </div>
    </template>
    <CheckboxMix
      class="restream-output-form__checkbox"
      label="Автоматическое переподключение"
      v-model="form.restart"
    />
    <div class="restream-output-form__buttons">
      <MixButton
        size="medium"
        type="secondary"
        class="restream-output-form__button restream-output-form__button_cancel"
        @click="$emit('close')"
      >
        Отмена
      </MixButton>
      <MixButton
        size="medium"
        type="primary"
        class="restream-output-form__button restream-output-form__button_save"
        @click="save"
        :disabled="!!Object.keys(errorsList).length"
      >
        Сохранить
      </MixButton>
    </div>
  </div>
</template>

<script>
import { computed, getCurrentInstance, onMounted, ref, toRefs } from 'vue';
import MixButton from '@core/src/components/common/buttons/MixButton.vue';
import InputMix from '@core/src/components/common/InputMix.vue';
import CheckboxMix from '@core/src/components/common/CheckboxMix.vue';
import SelectMix from '@core/src/components/common/SelectMix.vue';
import { splitUrl, copy } from '@/helpers/common.js';
import Joi from 'joi';
import CopyButton from '@core/src/components/common/buttons/CopyButton.vue';

// add custom rules for dublicate name
const joiExtends = Joi.extend((joi) => ({
  type: 'string',
  base: joi.string(),
  messages: {
    'string.snakeAlpha': '{{#label}} must be snake case',
  },
  rules: {
    isDublicate: {
      method(array, key) {
        return this.$_addRule({ name: 'isDublicate', args: { array, key } });
      },
      args: [
        {
          name: 'array',
          ref: true,
          assert: (value) => typeof value === 'object',
          message: 'must be a number',
        },
        {
          name: 'key',
          ref: true,
          assert: (value) => typeof value === 'string',
          message: 'must be a string',
        },
      ],
      validate(value, helpers, args) {
        const isDublicate = args.array.find((item) => item[args.key] === value);
        if (isDublicate) {
          return helpers.error('string.isDublicate', { value });
        }

        return value;
      },
    },
  },
}));

export default {
  name: 'RestremmOutputForm',
  props: {
    currentChangingOutput: {
      type: Object,
      default: null,
    },
    currentIndex: {
      type: Number,
      default: null,
    },
  },
  components: {
    MixButton,
    InputMix,
    SelectMix,
    CheckboxMix,
    CopyButton,
  },
  setup(props, { emit }) {
    const {
      proxy: { $store: store },
    } = getCurrentInstance();

    const optionsFormat = ref([
      { name: 'RTMP', value: 'rtmp' },
      { name: 'SRT', value: 'srt' },
    ]);

    const { currentChangingOutput, currentIndex } = toRefs(props);
    const form = ref({
      nameStream: '',
      format: '',
      hostName: '',
      port: '',
      latency: 1000,
      url: '',
      key: '',
      restart: false,
      stream_id: '',
      password: '',
    });

    const errorsList = ref({});

    const outputsList = computed(() => {
      return store.state.restream.outputs.filter(
        (item) => item.description !== currentChangingOutput.value?.description
      );
    });

    const schema = computed(() => {
      if (form.value.format?.value === 'srt') {
        return joiExtends.object().keys({
          nameStream: joiExtends
            .string()
            .required()
            .isDublicate(outputsList.value, 'description'),
          format: joiExtends.object().required(),
          hostName: joiExtends.string().required() || null,
          port: joiExtends.string().required() || null,
          password: joiExtends.string().min(10).max(80).allow(''),
          latency: joiExtends.number().min(1),
        });
      }
      return joiExtends.object().keys({
        nameStream: joiExtends
          .string()
          .required()
          .isDublicate(outputsList.value, 'description'),
        format: joiExtends.object().required(),
        url: joiExtends.string().required(),
        key: joiExtends.string().required(),
      });
    });

    const validateForm = () => {
      const options = {
        messages: {
          'string.empty': 'Обязательное поле',
        },
        abortEarly: false,
        stripUnknown: true,
      };
      const { error: { details = [] } = {} } = schema.value.validate(
        form.value,
        options
      );

      errorsList.value = details.reduce((acc, current) => {
        acc[current.context.key] = {
          message: current.message,
          type: current.type,
        };
        return acc;
      }, {});
    };

    const inputPort = () => {
      // eslint-disable-next-line
      const reg = /([^\/\:]+):\/\/([^\/]+):([0-9]+)/;
      form.value.hostName = `${form.value.hostName.replace(reg, '$1://$2')}`;

      validateForm();
    };

    const generateOutput = () => {
      let url = '';
      // eslint-disable-next-line
      const reg = /([^\/\:]+):\/\/([^\/]+):([0-9]+)/;
      if (form.value.format.value === 'srt') {
        url = `${form.value.hostName.replace(reg, '$1://$2')}:${
          form.value.port
        }${form.value.stream_id ? `/${form.value.stream_id}` : ''}`;
      } else {
        url = `${form.value.url}/${form.value.key}`;
      }

      const data = {
        url: url,
        restart: form.value.restart,
        srt_password:
          form.value.format.value === 'srt' ? form.value.password : null,
        srt_latency: form.value.latency | 0,
        description: form.value.nameStream,
        disabled: currentChangingOutput.value
          ? currentChangingOutput.value.disabled
          : true,
        // output_enable: currentChangingOutput.value
        //   ? !currentChangingOutput.value.disabled
        //   : false,
      };

      Object.keys(data).forEach((key) => {
        if (data[key] === null || data[key] === 0 || data[key] === '')
          delete data[key];
      });

      return data;
    };

    const addNewOutput = () => {
      if (!currentChangingOutput.value) {
        store.dispatch('restream/ADD_NEW_OUTPUT', generateOutput());
      } else {
        store.dispatch('restream/SAVE_OUTPUT', {
          output: generateOutput(),
          index: currentIndex.value,
        });
      }
      emit('close');
    };

    const save = () => {
      validateForm();
      addNewOutput();
    };

    onMounted(() => {
      if (!currentChangingOutput.value) return;
      const destination = currentChangingOutput.value;
      const format = destination?.url.includes('srt://')
        ? optionsFormat.value.find((item) => item.value === 'srt')
        : optionsFormat.value.find((item) => item.value === 'rtmp');

      const urlParams = splitUrl(currentChangingOutput.value?.url);

      //eslint-disable-next-line
      const reg = /([^\/\:]+):\/\/([^\/]+):([0-9]+)/;
      form.value = {
        nameStream: currentChangingOutput.value?.description,
        format: currentChangingOutput.value ? format : null,
        hostName: urlParams?.url.replace(reg, '$1://$2'),
        port: urlParams?.url.replace(reg, '$3'),
        latency: destination?.srt_latency,
        url: urlParams?.url,
        key: urlParams.key,
        restart: destination?.restart,
        stream_id: urlParams.stream_id,
        password: destination?.srt_password,
      };
    });

    const inputUrl = (value) => {
      //eslint-disable-next-line
      const regUrl = /([^\/\:]+):\/\/([^\/]+)\.([^\/]+)\//;
      let params = {};
      if (regUrl.test(value)) {
        params = value.split('/').reduceRight((A, e, i) => {
          if (A === null) return { key: e, url: [] };
          A.url.unshift(e);
          if (i === 0) {
            if (A.url.length === 3) A.key = null;
            A.url = A.url.join('/');
          }
          return A;
        }, null);
      }
      if (params.key) {
        form.value = { ...form.value, ...params };
      }
      validateForm();
    };

    return {
      form,
      optionsFormat,
      validateForm,
      errorsList,
      save,
      store,
      copy,
      inputPort,
      outputsList,
      inputUrl,
    };
  },
};
</script>

<style lang="scss" scoped>
.restream-output-form {
  &__flex-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 12px;
  }
  &__form-field {
    margin-bottom: 20px;
  }

  &__input {
    display: flex;
    align-items: center;
  }

  &__input-icon {
    margin-left: 12px;
    width: 18px;
    cursor: pointer;
  }

  &__buttons {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-direction: column;

    @include at('middle') {
      flex-direction: row;
    }
  }

  &__button {
    @include below('middle') {
      width: 100%;
    }

    &_cancel {
      margin-top: 10px;
      order: 1;

      @include at('middle') {
        order: 0;
        margin-top: auto;
        margin-right: 12px;
      }
    }
  }

  &__checkbox {
    margin-bottom: 30px;
  }
}
</style>
