import Vue from 'vue';
import Vuex from 'vuex';
import auth from './auth/index.js';
import dictionary from './dictionary/index.js';
import folder from './folder/index.js';
import admin from './admin/index.js';
import restream from './restream/index.js';

import createPersistedState from 'vuex-persistedstate';

Vue.use(Vuex);
const authState = createPersistedState({
  paths: ['auth'],
});

const store = new Vuex.Store({
  state: {},
  mutations: {},
  modules: {
    auth,
    folder,
    dictionary,
    admin,
    restream,
  },
  plugins: [authState],
});

export default store;
