<template>
  <TableMix
    :tableData="mapTableData"
    :rowHeight="40"
    :widthControls="370"
    :bodyHeigh="466"
    :disabledScroll="isShowModal"
  >
    <template #head-controls>status</template>
    <template #row-controls="{ status, id, mixer_id }">
      <ProjectControls
        :status="status"
        :projectId="id"
        :mixerId="mixer_id"
        @showHideModal="
          (value) => {
            isShowModal = value;
          }
        "
        @deleteProject="deleteProject(id)"
      ></ProjectControls>
    </template>
  </TableMix>
</template>

<script>
import TableMix from '@/components/common/TableMix.vue';
import { computed, toRefs, ref } from 'vue';
import ProjectControls from '@/components/tables/controls/ProjectControls.vue';
import store from '@/store';

export default {
  props: {
    tableData: {
      default: () => [],
      type: Array,
    },
  },
  components: { TableMix, ProjectControls },
  setup(props) {
    const { tableData } = toRefs(props);
    let isShowModal = ref(false);

    const compare = (a, b) => {
      if (a.project > b.project) {
        return 1;
      }
      if (a.project < b.project) {
        return -1;
      }
      return 0;
    };

    const mapTableData = computed(() => {
      return {
        head: ['project', 'node'],
        rows: tableData.value
          .map((item) => ({
            project: item.ppt_desc,
            node: item.info.node_name,
            status: item.run_now,
            id: item.ppt_id,
            mixer_id: item.info.mixer_id,
          }))
          .sort(compare),
      };
      // return table.sort(compare);
    });

    const deleteProject = async (id) => {
      store.dispatch('admin/DELETE_PROJECT', {
        project_id: id,
        user_id: store.state.admin.user.user_id,
      });
    };

    return {
      mapTableData,
      deleteProject,
      isShowModal,
    };
  },
};
</script>

<style lang="scss" scoped>
.project-list-table {
  &__controls {
    display: flex;
  }

  &__button {
    position: relative;

    &:not(:last-child) {
      margin-right: 18px;
    }

    &:first-child {
      margin-right: 36px;
    }
  }

  &__delete-controls {
    position: absolute;
    background-color: var(--color-background-inverted);
    height: 40px;
    width: 160px;
    right: 0;
    z-index: 9;
    box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.12),
      0px 12px 20px rgba(0, 0, 0, 0.14);
    border-radius: 6px;
    top: 0;
  }
}
</style>
